import React, { useEffect, useState } from "react";
import RequestHeader from "../../../../Components/nurse-manager/requests/RequestHeader";
import FilterSideBar from "../../../../Components/nurse-manager/employees/FilterSideBar";
import SimpleBar from "simplebar-react";
import FilterRequests from "../../../../Components/nurse-manager/requests/FilterRequests";
import { Avatar, Chip } from "@heathmont/moon-core-tw";
import Table from "../../../../Components/nurse-manager/View Table/NurseManagerTable";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  getRequestsOfEmployees,
  getSpecificEmployeeRequests,
} from "../../../../services/nurse-manager-services/nursemanager.service";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import RequestModal from "../../../../Components/nurse-manager/Widgets/modal/RequestModal";
import { RootState } from "../../../../store/store";
import { years } from "../../../../utils/years";
import { months } from "../../../../utils/months";
import DropDown from "../../../../Components/nurse-manager/UI/DropDown";
import {
  getBorderColor,
  getTextColor,
} from "../../../../Components/Colors/colorSetting";
interface RequestedDate {
  date: string;
  reason: string;
  time: string;
}

interface RequestData {
  result: {
    requestedBy: {
      _id: string;
      employeeId: string;
      role: string;
      employeeName: string;
    };
    type: string;
    status: string;
    month: number;
    year: number;
    requestedDays: number;
    requestedDates: RequestedDate[];
  };
}

interface EmployeeRecord {
  _id: string;
  employeeId: string;
  employeeName: string;
  role: string;
  requestType: string;
  requestStatus: string;
  createdAt: string;
  details: number;
}

interface RecordsResponse {
  currentMonthROLeaves: number;
  currentMonthTotalLeaves: number;
  records: EmployeeRecord[];
  totalRecords: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

const Requests = () => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [reqTimeOffViewRes, setReqTimeOffViewRes] = useState<RecordsResponse>();
  const [selectedMonthOption, setSelectedMonthOption] = useState<any>({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });
  const [sidebarData, setSidebarData] = useState([
    {
      id: 1,
      label: "Name",
      isOpen: true,
      subItems: [
        { id: 2, label: "A_Z", name: "A-Z", isSelected: false },
        { id: 3, label: "Z_A", name: "Z-A", isSelected: false },
      ],
    },
    // {
    //   id: 4,
    //   label: "Status",
    //   isOpen: true,
    //   subItems: [
    //     { id: 5, label: "Applied", name: "Applied", isSelected: false },
    //     { id: 10, label: "Pending", name: "Pending", isSelected: false },
    //     { id: 12, label: "Rejected", name: "Rejected", isSelected: false },
    //     { id: 11, label: "Accepted", name: "Accepted", isSelected: false },
    //   ],
    // },
    {
      id: 6,
      label: "Date",
      isOpen: true,
      subItems: [
        {
          id: 7,
          label: "Latest_First",
          name: "Latest First",
          isSelected: false,
        },

        {
          id: 13,
          label: "Oldest_First",
          name: "Oldest First",
          isSelected: false,
        },
      ],
    },
    // {
    //   id: 8,
    //   label: "Request Type",
    //   isOpen: true,
    //   subItems: [
    //     { id: 9, label: "RO", name: "RO", isSelected: false },
    //     { id: 10, label: "Swap", name: "Swap", isSelected: false },
    //   ],
    // },
  ]);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const [filters, setFilters] = useState(false);
  const [calendarData, setCalendarData] = useState<RequestData>(
    {} as RequestData
  );
  const [specificDayData, setSpecificDayData] = useState<RequestData>(
    {} as RequestData
  );
  const handleSidebarDataUpdate = (data: any) => {
    console.log("DATA", data);
    setSidebarData(data);
    setFilters(true);
  };
  const handleRowClick = (row: any) => {
    setIsOpen(true);
    fetchSpecificEmployeeRequests(row?.original?._id);
    console.log("ROW", row);
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const columnHelper = createColumnHelper<EmployeeRecord>();
  const columns = [
    columnHelper.accessor("employeeId", {
      header: () => "ID",
      cell: (info) => (
        <div className="flex-1 2xl:text-moon-16 text-moon-14 ">
          <span className="">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("employeeName", {
      // id: "employeeName",
      enableSorting: false,
      header: () => (
        <span className="flex items-center justify-between">Name </span>
      ),
      cell: (info) => (
        <div className="">
          <div className="flex justify-between items-center border-none cursor-pointer">
            <span className="inline-flex rounded-full shadow-md  mr-[15px]">
              <Avatar
                size="md"
                className={`rounded-full border-2  overflow-hidden ${getBorderColor(
                  info?.row?.original?.role
                )}`}
                imageUrl={""}
              />
            </span>
            <div className="flex-1">
              <Chip
                size="sm"
                className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${getTextColor(
                  info?.row?.original?.role
                )}`}
              >
                {info?.row?.original?.role}
              </Chip>
              <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
                {info?.row?.original?.employeeName}
              </h4>
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("requestType", {
      enableSorting: false,
      header: () => "Request Type",
      cell: (info) => <h4 className="block">{info.renderValue()}</h4>,
    }),
    columnHelper.accessor("requestStatus", {
      enableSorting: false,
      header: () => "Status",
      cell: (info) => (
        <div className="flex-[0_0_217px] 2xl:text-moon-16 text-moon-14 ">
          <h4
            className={
              info?.renderValue() === "Pending"
                ? `text-[#249CBA] rounded-moon-i-sm  py-1`
                : info?.renderValue() === "Rejected"
                ? ` text-dodoria rounded-moon-i-sm  py-1`
                : info?.renderValue() === "Applied"
                ? ` text-[#8697A2] rounded-moon-i-sm  py-1`
                : ` text-[#4AD15F] rounded-moon-i-sm  py-1`
            }
          >
            {info?.renderValue()}
          </h4>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row?.details, {
      enableSorting: false,
      id: "details",
      header: "Days",
      cell: (info) => (
        <div className="flex-[0_0_217px] 2xl:text-moon-16 text-moon-14 ">
          <h4 className="block">{info?.renderValue()}</h4>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row?.createdAt, {
      enableSorting: false,
      id: "createdAt",
      header: "Request Date",
      cell: (info) => (
        <div className="flex-[0_0_217px] 2xl:text-moon-16 text-moon-14 ">
          <h4 className="block">
            {moment(info?.renderValue())?.format("MMM-DD-YYYY, hh:mm A")}
          </h4>
        </div>
      ),
    }),
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = React.useState(() => []);
  const { date } = useSelector((state: RootState) => state.time);
  const [year, setYear] = useState({
    name: moment().format("YYYY"),
    year: moment().format("YYYY"),
    label: "year",
  });
  const [month, setMonth] = useState({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });
  const fetchRequestsOfEmployees = async (pageChange: number) => {
    console.log("selectedMonthOption?.label,", selectedMonthOption?.label);
    const selectedItem: any = sidebarData?.flatMap((menuItem: any) =>
      menuItem?.subItems?.filter((subItem: any) => subItem?.isSelected)
    );

    const extractedData = selectedItem?.reduce((acc: any, item: any) => {
      acc[item?.label] = item?.isSelected;
      return acc;
    }, {});
    setIsLoading(true);

    const res: any = await getRequestsOfEmployees({
      page: pageChange,
      month: month?.label,
      year: year?.year,
      ...extractedData,
      search: searchQuery,
    });

    if (res?.res) {
      setData(res?.res?.records);
      setReqTimeOffViewRes(res?.res);
    }
    setIsLoading(false);
  };

  const fetchSpecificEmployeeRequests = async (id: string) => {
    const res: any = await getSpecificEmployeeRequests(
      selectedMonthOption?.label,
      moment().year(),
      id
    );

    if (res?.res) {
      setCalendarData(res?.res);
    }
  };

  useEffect(() => {
    const currentDate = moment();

    setFetchData(true);
  }, []);

  useEffect(() => {
    if (filters && fetchData) {
      fetchRequestsOfEmployees(1);
    }
  }, [sidebarData, fetchData, month, year]);

  useEffect(() => {
    if (fetchData) {
      const debounceTimeout = setTimeout(() => {
        fetchRequestsOfEmployees(1); // Trigger the fetch with the debounced search term
      }, 1000); // Adjust the debounce delay as needed
      return () => clearTimeout(debounceTimeout);
    }
  }, [searchQuery]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className="bg-slate-100">
      <RequestHeader
        data={reqTimeOffViewRes}
        bgColor="bg-white"
        selectedMonthOption={selectedMonthOption}
        setSelectedMonthOption={setSelectedMonthOption}
      />
      <div className="grid lg:grid-cols-12">
        <div className="2xl:col-span-2 lg:col-span-3">
          <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
            <div className="bg-whitept-[30px] 2xl:p-[30px] p-5">
              <h3 className="text-moon-20 font-semibold ">Filters</h3>
            </div>

            <div
              className={`bg-gohan  flex-col gap-2 rounded-moon-s-lg lg:flex hidden`}
            >
              <SimpleBar className="px-[30px] 2xl:h-[calc(100dvh-315px)] lg:h-[calc(100dvh-296px)] w-full">
                <FilterSideBar
                  onDataUpdate={handleSidebarDataUpdate}
                  sidebarData={sidebarData}
                />
              </SimpleBar>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-10 lg:col-span-9">
          <div
            className={`flex 2xl:p-[20px_30px_28px] p-[20px_16px_28px] gap-3 `}
          >
            {" "}
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"monthName"}
                value={month}
                options={months}
                setOptions={setMonth}
              />
            </div>
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"name"}
                value={year}
                options={years}
                setOptions={setYear}
              />
            </div>
            <FilterRequests setSearchQuery={setSearchQuery} />
          </div>
          {/* <SimpleBar className="p-[0_30px_20px] h-[calc(100vh-376px)] "> */}
          {/* <RequestListing /> */}
          {/* </SimpleBar> */}
          {!isLoading && (
            <Table
              table={table}
              tableData={reqTimeOffViewRes as RecordsResponse}
              handlePageChange={fetchRequestsOfEmployees}
              handleRowClick={handleRowClick} // Add this prop
            />
          )}
          {/* <div className="flex flex-wrap px-[30px] py-[15px] items-center">
            <div className="w-2/5">
              <p className="text-moon-12 text-center">
                Showing 12 of <span className="text-trunks">26</span>
              </p>
            </div>
            <div className="w-3/5">
              <Pagination
                totalPages={26}
                currentPage={page}
                setCurrentPage={handlePageChange}
              >
                <Pagination.PrevButton as="div">
                  {({ disabled }) => (
                    <IconButton
                      icon={
                        <ControlsChevronLeftSmall className="rtl:rotate-180" />
                      }
                      className="bg-hit"
                      size="sm"
                      disabled={disabled}
                      aria-label="Previous"
                    />
                  )}
                </Pagination.PrevButton>
                <Pagination.Pages as="button" className="bg-btn" />
                <Pagination.NextButton as="div">
                  {({ disabled }) => (
                    <IconButton
                      icon={
                        <ControlsChevronRightSmall className="rtl:rotate-180" />
                      }
                      variant="primary"
                      className="bg-hit"
                      size="sm"
                      disabled={disabled}
                      aria-label="Next"
                    />
                  )}
                </Pagination.NextButton>
              </Pagination>
            </div>
          </div> */}
        </div>
      </div>

      <RequestModal
        isOpen={isOpen}
        setIsOpen={handleRowClick}
        onClose={handleClose}
        calendarData={calendarData}
        selectedMonthOption={selectedMonthOption}
      />
    </div>
  );
};

export default Requests;
