import { IEmployeeJob } from "../../types/operation-manager-interfaces/IEmployeeJob.interface";
import { IJobHistory } from "../../types/operation-manager-interfaces/IJobHistroy.interface";
import { IPatientJob } from "../../types/operation-manager-interfaces/IPatientJob.interface";
import { api } from "../../utils/axios";

export const fetchRecentJobs = async (search: string = "") => {
  try {
    const { data, ok } = await api.get<IJobHistory[]>(`/jobs`, { search });
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchRecentHistory = async (
  search: string = "",
  type: string = "Employees"
) => {
  try {
    const { data, ok } = await api.get<IJobHistory[]>(`/jobs/history`, {
      search,
      type,
    });
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const fetchJobHistory = async (
  id: string,
  page: number = 1,
  search: string = "",
  tab?: number
) => {
  try {
    const params: any = { page, search };
    if (tab && tab !== 0) {
      params.alreadyExists = tab === 2;
    }
    const { data, ok } = await api.get<IJobHistory>(`/jobs/${id}`, params);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const deleteJob = async (id: string) => {
  try {
    const { data, ok } = await api.delete(`/jobs/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const submitJob = async (id: string) => {
  try {
    const { data, ok } = await api.post(`/jobs/submit/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateJobPatient = async (patient: IPatientJob) => {
  try {
    const { _id, ...rest } = patient;
    const { data, ok } = await api.put(`/jobs/patient/update/${_id}`, rest);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const updateJobEmployee = async (employee: Partial<IEmployeeJob>) => {
  try {
    const { _id, ...rest } = employee;
    const { data, ok } = await api.put(`/jobs/employee/update/${_id}`, rest);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const deletePatient = async (id: string) => {
  try {
    const { data, ok } = await api.delete(`/jobs/patient/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};

export const deleteEmployee = async (id: string) => {
  try {
    const { data, ok } = await api.delete(`/jobs/employee/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
export const deleteLeave = async (id: string) => {
  try {
    const { data, ok } = await api.delete(`/jobs/leave/${id}`);
    if (ok) {
      return { res: data, err: null };
    }
  } catch (error) {
    return { res: null, err: error };
  }
};
