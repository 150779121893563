import React from "react";
import { NotificationsAlert } from "@heathmont/moon-icons-tw";
import { Avatar } from "@heathmont/moon-core-tw";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { IEmployee } from "../../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";
import { getBorderColor } from "../../../Colors/colorSetting";

const CardPoolTracking: React.FC = () => {
  const { employees } = useSelector((state: RootState) => state.weekFilter);
  console.log("EMPLO", employees);
  // const employees = [
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["leader"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["nurse"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "John Doe",
  //     roles: ["nurse"],
  //     utilization: {
  //       current: 24,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   {
  //     name: "Bessie Cooper",
  //     roles: ["supervisor"],
  //     utilization: {
  //       current: 36,
  //       total: 48,
  //     },
  //   },
  //   // Add more employee objects with their roles and utilization data
  // ];

  return (
    <>
      {employees
        ? employees?.map((employee: IEmployee, index: any) => (
            <div
              key={index}
              className="p-[10px] border border-beerus rounded-[8px]"
            >
              <div className="flex items-start">
                <Avatar
                  className={`rounded-full border-2 
              ${getBorderColor(employee?.role)} 
              overflow-hidden`}
                  imageUrl=""
                  size="lg"
                />
                <div className="flex-1 px-[10px]">
                  <h3 className="font-semibold capitalize mb-1 truncate max-w-[200px]">
                    {employee?.employeeName}
                  </h3>
                  <p className="capitalize text-moon-10 text-grey-100">
                    Hours Utilization
                  </p>
                  <div className="text-moon-12 font-normal inline-flex items-center">
                    <strong className="font-semibold">
                      {employee?.hoursSpent}
                    </strong>{" "}
                    /{employee?.hoursAllocated}
                  </div>
                </div>
                <NotificationsAlert />
              </div>
            </div>
          ))
        : null}
    </>
  );
};

export default CardPoolTracking;
