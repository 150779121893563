import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import { notificationSocket } from "../../../utils/notifications.socket.service";
import { apiBaseUrl } from "../../../constants/environmentConstants";
import {
  deleteJob,
  fetchRecentJobs,
} from "../../../services/operation-manager-services/job.service";
import { IJobHistory } from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import { api } from "../../../utils/axios";
import TitlePage from "../components/TitlePage";
import FilterSearch from "../components/FilterSearch";
import UploadedCsv from "../components/UploadedCsv";
import UploaadDocumnentModal from "../components/UploaadDocumnentModal";
import ConfirmDeleteModal from "../../super-admin/settings/hospital/widgets/ConfirmationModal";

import {
  jobsSelector,
  setHistory,
  setJobs,
} from "../../../features/history/history.slice";
import { useNavigate } from "react-router-dom";

const Operation_history = () => {
  const isMounted = useRef(true);
  const { User } = useSelector((state: RootState) => state.auth);
  const history = useSelector(jobsSelector);
  const navigate = useNavigate();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [search, setSearch] = useState("");
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const dispatch = useDispatch();

  const getJobHistory = async () => {
    const res: any = await fetchRecentJobs(search);

    if (res.res) {
      dispatch(setJobs(res?.res));
    }
  };

  const initializeSocket = () => {
    notificationSocket.socket?.on("JobError", (data: any) => {
      const message = JSON.parse(data.message);
      dispatch(setSnackBar("notification"));
      dispatch(setToastMessage(message?.message));
      navigate(`job/${message.jobId}`);
    });
    notificationSocket.socket?.on("JobSuccessful", (data) => {
      const message = JSON.parse(data.message);
      dispatch(setSnackBar("notification"));
      dispatch(setToastMessage(message?.message));
      navigate(`job/${message.jobId}`);
    });
  };

  const handleFileChange = async (file: File, type: string) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);

      const response = await api.post("jobs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (!response.ok) {
        throw new Error("Upload failed");
      }
      getJobHistory();
    } catch (err) {
      console.error(err);
      // setError(err instanceof Error ? err.message : "Upload failed");
    } finally {
    }
  };

  const handleConfirmAction = async () => {
    const response = await deleteJob(deletedId);
    if (response?.res) {
      getJobHistory();
    }
  };

  useEffect(() => {
    if (!User?.id || !apiBaseUrl) return;
    initializeSocket();
    getJobHistory();
  }, [User?.id, apiBaseUrl]);

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    const timer = setTimeout(() => {
      getJobHistory();
    }, 500);

    // Cleanup timeout on each searchTerm change
    return () => clearTimeout(timer);
  }, [search]);

  const handleDelete = (id: string, name: string) => {
    setConfirmationData({
      title: "Are you sure you want to delete this record?",
      actionFor: "History",
      message: (
        <p className="text-moon-18 mb-[28px]">
          {" "}
          The file <strong className="font-semibold ">{name}</strong>
          <br />
          and all its related data will be deleted
        </p>
      ),
      confirmAction: "Delete",
    });
    setDeletedId(id);
    setIsOpenConfirm(true);
  };
  return (
    <>
      <TitlePage
        title="History"
        content="See all the data with reference to the hospital"
      />
      <div className="py-6">
        {/* <FilterSearch search={search} setSearch={setSearch} type="History" /> */}
        <div className="pt-6">
          <UploadedCsv
            fileGroups={history}
            onUploadData={handleFileChange}
            onDelete={handleDelete}
          />
        </div>
      </div>
      <ConfirmDeleteModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};

export default Operation_history;
