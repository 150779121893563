import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScheduleHeader from "../../Components/nurse-manager/layouts/header/ScheduleHeader";
import "../../";
import useIdleTime from "../../hooks/branch-admin/useIdleTime";
import AddSchedule from "../nurse-manager/Nurse-Manager/add-schedule";
import Operation_history from "./history";
import PatientFile from "./file-detail";
import Profile from "../profile";

const Operation_Manager_Routes = () => {
  const user = JSON.parse(sessionStorage.getItem("user")!);
  useIdleTime();
  return (
    <>
      <Router>
        <header>
          <ScheduleHeader />
        </header>
        <Routes>
          <Route path="/" element={<Operation_history />} />
          <Route path="/job/:id" element={<PatientFile />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default Operation_Manager_Routes;
