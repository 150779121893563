import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import { Avatar, Chip } from "@heathmont/moon-core-tw";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import moment from "moment";
import { Icon } from "@iconify/react";
import { getSwapRequests } from "../../../services/nurse-manager-services/nursemanager.service";
import RequestHeader from "../../../Components/nurse-manager/requests/RequestHeader";
import FilterSideBar from "../../../Components/nurse-manager/employees/FilterSideBar";
import FilterRequests from "../../../Components/nurse-manager/requests/FilterRequests";
import Table from "../../../Components/nurse-manager/View Table/NurseManagerTable";
import DropDown from "../../../Components/nurse-manager/UI/DropDown";
import { years } from "../../../utils/years";
import { months } from "../../../utils/months";

import {
  getBorderColor,
  getTextColor,
} from "../../../Components/Colors/colorSetting";
interface RequestedDate {
  date: string;
  reason: string;
  time: string;
}

interface RequestData {
  result: {
    requestedBy: {
      _id: string;
      employeeId: string;
      role: string;
      employeeName: string;
    };
    type: string;
    status: string;
    month: number;
    year: number;
    requestedDays: number;
    requestedDates: RequestedDate[];
  };
}

interface EmployeeRecord {
  employee: {
    firstName: string;
    lastName: string;
    employeeId: string;
    role: string;
  };
  branch: { branchName: string };
  day: number;
  month: number;
  year: number;
  shift: string;
  active: boolean;
  createdAt: string;
}

interface RecordsResponse {
  currentMonthROLeaves: number;
  currentMonthTotalLeaves: number;
  records: EmployeeRecord[];
  totalRecords: number;
  currentPage: number;
  limit: number;
  totalPages: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

const SwapRequests = () => {
  const mounted = useRef<boolean>(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedMonthOption, setSelectedMonthOption] = useState<any>({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });
  const [sidebarData, setSidebarData] = useState([
    {
      id: 1,
      label: "Name",
      isOpen: true,
      subItems: [
        { id: 2, label: "A_Z", name: "A-Z", isSelected: false },
        { id: 3, label: "Z_A", name: "Z-A", isSelected: false },
      ],
    },
    {
      id: 6,
      label: "Date",
      isOpen: true,
      subItems: [
        {
          id: 7,
          label: "Latest_First",
          name: "Latest First",
          isSelected: false,
        },

        {
          id: 13,
          label: "Oldest_First",
          name: "Oldest First",
          isSelected: false,
        },
      ],
    },
  ]);
  const [searchQuery, setSearchQuery] = useState("");

  const generateSortString = (): string => {
    const sortParams: string[] = [];

    sidebarData.forEach((item) => {
      const selectedSubItem = item.subItems.find(
        (subItem) => subItem.isSelected
      );
      if (selectedSubItem) {
        switch (item.label) {
          case "Name":
            sortParams.push(
              `employeeName:${selectedSubItem.label === "A_Z" ? "asc" : "desc"}`
            );
            break;
          case "Date":
            sortParams.push(
              `date:${
                selectedSubItem.label === "Latest_First" ? "desc" : "asc"
              }`
            );
            break;
        }
      }
    });

    return sortParams.join(",");
  };

  const handleSidebarDataUpdate = (data: any) => {
    setSidebarData(data);
  };
  const handleRowClick = (row: any) => {};
  const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  };

  const columnHelper = createColumnHelper<EmployeeRecord>();
  const columns = [
    columnHelper.accessor("employee", {
      header: () => "ID",
      cell: (info) => (
        <div className="flex-1 2xl:text-moon-16 text-moon-14 ">
          <span className="">{info.row.original.employee.employeeId}</span>
        </div>
      ),
    }),
    columnHelper.accessor("employee", {
      // id: "employeeName",
      enableSorting: false,
      header: () => (
        <span className="flex items-center justify-between">Name </span>
      ),
      cell: (info) => (
        <div className="">
          <div className="flex justify-between items-center border-none cursor-pointer">
            <span className="inline-flex rounded-full shadow-md  mr-[15px]">
              <Avatar
                size="md"
                className={`rounded-full border-2  overflow-hidden ${getBorderColor(
                  info?.row?.original?.employee.role
                )}`}
                imageUrl={""}
              />
            </span>
            <div className="flex-1">
              <Chip
                size="sm"
                className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${getTextColor(
                  info?.row?.original?.employee.role
                )}`}
              >
                {info?.row?.original?.employee.role}
              </Chip>
              <h4 className="2xl:text-moon-16 text-moon-14  font-semibold">
                {info?.row?.original?.employee.firstName +
                  " " +
                  info.row.original.employee.lastName}
              </h4>
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor("day", {
      enableSorting: false,
      header: () => "Shift Date",
      cell: (info) => (
        <div className="flex-[0_0_217px] 2xl:text-moon-16 text-moon-14 ">
          <h4 className="block">
            {moment(
              new Date(
                info.row.original.year,
                info.row.original.month,
                info.row.original.day
              )
            )?.format("DD MMMM yyyy")}
          </h4>
        </div>
      ),
    }),
    columnHelper.accessor("shift", {
      enableSorting: false,
      header: () => "Request Type",
      cell: (info) => (
        <div
          className={`inline-flex items-center gap-[5px] font-semibold 2xl:text-moon-16 text-moon-14  text-${
            info.row.original.shift === "day" ? "krillin" : "#272D36"
          }`}
        >
          {info.row.original.shift === "day" ? (
            <span className="text-moon-18">
              <Icon icon="uil:sun" />
            </span>
          ) : (
            <span className="text-moon-18">
              <Icon icon="uil:moon" />
            </span>
          )}
          {capitalizeFirstLetter(info.row.original.shift)}
        </div>
      ),
    }),
    columnHelper.accessor("createdAt", {
      enableSorting: false,
      id: "createdAt",
      header: "Requested On",
      cell: (info) => (
        <div className="flex-[0_0_217px] 2xl:text-moon-16 text-moon-14 ">
          <h4 className="block">
            {moment(info?.renderValue())?.format("DD MMMM yyyy")}
          </h4>
        </div>
      ),
    }),
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = React.useState<EmployeeRecord[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [year, setYear] = useState({
    name: moment().format("YYYY"),
    year: moment().format("YYYY"),
    label: "year",
  });
  const [month, setMonth] = useState({
    name: moment().format("MMMM"),
    monthName: moment().format("MMMM"),
    label: moment().format("M"),
  });

  const fetchRequestsOfEmployees = async (page?: number) => {
    const sort = generateSortString();
    const res: any = await getSwapRequests({
      month: Number(month?.label),
      year: year?.year,
      sort,
      searchName: searchQuery,
      page,
    });
    if (res) {
      setData(res.data);
      setPagination(res.pagination);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    console.log(searchQuery);
    const timeOut = setTimeout(() => fetchRequestsOfEmployees(1), 1000);
    return () => clearTimeout(timeOut);
  }, [searchQuery]);

  useEffect(() => {
    fetchRequestsOfEmployees(1);
  }, [month, year, sidebarData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div className="bg-slate-100">
      <RequestHeader
        title="Swap Requests"
        subTitle="All requests for shift swap"
        // data={reqTimeOffViewRes}
        bgColor="bg-white"
        selectedMonthOption={selectedMonthOption}
        setSelectedMonthOption={setSelectedMonthOption}
      />
      <div className="grid grid-cols-12 flex-wrap">
        <div className="2xl:col-span-2 col-span-3">
          <div className="border-r border-beerus bg-white shadow-[0_8px_24px_-6px_rgba(0,0,0,0.08)]">
            <div className="bg-whitept-[30px] p-[30px]">
              <h3 className="text-moon-20 font-semibold ">Filters</h3>
            </div>

            <div className={`bg-gohan flex flex-col gap-2 rounded-moon-s-lg `}>
              <SimpleBar className="px-[30px] 2xl:h-[calc(100vh-290px)] h-[calc(100vh-290px)] w-full">
                <FilterSideBar
                  onDataUpdate={handleSidebarDataUpdate}
                  sidebarData={sidebarData}
                />
              </SimpleBar>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-10 col-span-9">
          <div className={`flex 2xl:p-[20px_30px_28px] p-[20px_16px_28px] `}>
            {" "}
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"monthName"}
                value={month}
                options={months}
                setOptions={setMonth}
              />
            </div>
            <div className="max-w-[173px] w-full">
              <DropDown
                field={"name"}
                value={year}
                options={years}
                setOptions={setYear}
              />
            </div>
            <FilterRequests setSearchQuery={setSearchQuery} />
          </div>
          {/* <SimpleBar className="p-[0_30px_20px] h-[calc(100vh-376px)] "> */}
          {/* <RequestListing /> */}
          {/* </SimpleBar> */}
          {/* {!isLoading && ( */}
          <Table
            table={table}
            tableData={{
              records: data,
              currentPage: pagination.currentPage,
              totalRecords: pagination.totalCount,
              limit: pagination.pageSize,
              hasNextPage: pagination.hasNextPage,
              hasPrevPage: pagination.hasPrevPage,
            }}
            handlePageChange={fetchRequestsOfEmployees}
            handleRowClick={handleRowClick} // Add this prop
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default SwapRequests;
