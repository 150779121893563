import { IconButton } from "@heathmont/moon-core-tw";
import { ControlsEye, GenericDelete } from "@heathmont/moon-icons-tw";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";
import { IJobHistory } from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import moment from "moment";
import GenericTable from "./Table";
import { useSelector } from "react-redux";
import ConfirmDeleteModal from "../../super-admin/settings/hospital/widgets/ConfirmationModal";
import { historySelector } from "../../../features/history/history.slice";
import { deleteJob } from "../../../services/operation-manager-services/job.service";
import { useNavigate } from "react-router-dom";

interface HistoryProps {
  getHistory: (type: string) => void;
  selectedType: string | null;
}

const HistoryTable = ({ getHistory, selectedType }: HistoryProps) => {
  const columnHelper = createColumnHelper<IJobHistory>();
  const history = useSelector(historySelector);
  const navigate = useNavigate();
  const [deletedId, setDeletedId] = useState("");
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [confirmationData, setConfirmationData] = useState<any>(null);

  const columns = [
    columnHelper.accessor("fileName", {
      header: () => (
        <span className="px-4 py-2 text-left capitalize font-semibold text-xs h-12 sticky top-0 z-10 bg-neutral-200">
          File Name
        </span>
      ),
      cell: (info) => (
        <span className="px-4 py-2 text-sm text-black">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => (
        <span className="px-4 py-2 text-left capitalize font-semibold text-xs h-12 sticky top-0 z-10 bg-neutral-200">
          File Name
        </span>
      ),
      cell: (info) => (
        <span className="px-4 py-2  text-sm text-black">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor("createdAt", {
      header: () => (
        <span className="px-4 py-2 text-left capitalize font-semibold text-xs h-12 sticky top-0 z-10 bg-neutral-200">
          Created On
        </span>
      ),
      cell: (info) => (
        <span className="px-4 py-2 text-sm text-black">
          {info.row.original.createdAt &&
            moment(new Date(info.row.original.createdAt)).format(
              "DD MMM YYYY hh:mm a"
            )}
        </span>
      ),
    }),
    columnHelper.accessor("_id", {
      header: () => (
        <span className="px-4 py-2 text-left capitalize font-semibold text-xs h-12 sticky top-0 z-10 bg-neutral-200">
          Action
        </span>
      ),
      cell: (info) => (
        <div className="flex items-center gap-2">
          <IconButton
            onClick={() => handleView(info.getValue())}
            className="bg-primary/10 text-primary"
          >
            <ControlsEye />
          </IconButton>
          <IconButton
            onClick={() =>
              handleDelete(info.getValue(), info.row.original.fileName)
            }
            className="bg-red-50 text-red-500"
          >
            <GenericDelete />
          </IconButton>
        </div>
      ),
    }),
  ];

  const handleView = (id: any) => {
    navigate(`job/${id}`);
  };

  const handleDelete = (id: string, name: string) => {
    setConfirmationData({
      title: "Are you sure you want to delete this record?",
      actionFor: "History",
      message: (
        <p className="text-moon-18 mb-[28px]">
          {" "}
          The file <strong className="font-semibold ">{name}</strong>
          <br />
          and all its related data will be deleted
        </p>
      ),
      confirmAction: "Delete",
    });
    setDeletedId(id);
    setIsOpenConfirm(true);
  };

  const handleConfirmAction = async () => {
    const response = await deleteJob(deletedId);
    if (response?.res) {
      getHistory(selectedType || "");
    }
  };

  return (
    <>
      <GenericTable noPagination data={history} columns={columns} />
      <ConfirmDeleteModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};

export default HistoryTable;
