import { ArrowsLeftShort } from "@heathmont/moon-icons-tw";
import { useNavigate } from "react-router-dom";

interface TitleProps {
  content: string;
  title: string;
  backButton?: boolean;
}

const TitlePage: React.FC<TitleProps> = ({ content, title, backButton }) => {
  const navigate = useNavigate();
  return (
    <div className="sm:px-7 px-4 bg-hit min-h-[118px]">
      <div className="flex items-end py-[23px]">
        <div className="w-full px-[16px]">
          <div className="flex">
            {backButton && (
              <i
                onClick={() => navigate("/")}
                className="2xl:text-moon-48 text-moon-24 font-semibold text-white"
              >
                <ArrowsLeftShort />
              </i>
            )}
            <div className="flex-1">
              <h3 className="2xl:text-moon-32 text-moon-24 font-semibold text-white">
                {title}
              </h3>
              <p className="text-white 2xl:text-moon-16 text-moon-14 mt-[8px]">
                {content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitlePage;
