import { io, Socket } from "socket.io-client";

interface SocketOptions {
  reconnection: boolean;
  reconnectionAttempts: number;
  reconnectionDelay: number;
  reconnectionDelayMax: number;
  timeout: number;
}

class NotificationSocketClass {
  public socket: Socket | null = null;
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private baseDelay = 1000; // 1 second
  private currentUserId: string | null = null;
  private apiBaseUrl: string | null = null;

  private readonly socketOptions: SocketOptions = {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
  };

  initializeConnection = (apiBaseUrl: string, userId: string) => {
    // Don't create a new connection if we already have one
    if (this.socket?.connected) {
      console.info("Socket already connected");
      return;
    }

    this.currentUserId = userId;
    this.apiBaseUrl = apiBaseUrl;

    try {
      this.socket = io(apiBaseUrl, {
        ...this.socketOptions,
        auth: { userId },
        transports: ["websocket", "polling"], // Try WebSocket first, fallback to polling
      });

      this.setupEventListeners();
      this.socket.connect();
      this.provideUserId(userId);
    } catch (error) {
      console.error("Error initializing socket connection:", error);
      this.handleReconnection();
    }
  };

  private setupEventListeners = () => {
    if (!this.socket) return;

    this.socket.on("connect", () => {
      console.info("Socket connected successfully");
      this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
      this.emitConnectionStatus(true);
    });

    this.socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      this.emitConnectionStatus(false);
      this.handleReconnection();
    });

    this.socket.on("disconnect", (reason) => {
      console.warn("Socket disconnected:", reason);
      this.emitConnectionStatus(false);

      if (reason === "io server disconnect" || reason === "transport close") {
        this.handleReconnection();
      }
    });

    this.socket.on("error", (error) => {
      console.error("Socket error:", error);
      this.emitConnectionStatus(false);
      this.handleReconnection();
    });

    // Handle ping timeout
    this.socket.on("ping", () => {
      console.debug("Socket ping");
    });

    this.socket.on("pong", (latency) => {
      console.debug("Socket pong, latency:", latency);
    });
  };

  private handleReconnection = () => {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.error("Max reconnection attempts reached");
      this.emitConnectionStatus(false);
      return;
    }

    // Exponential backoff
    const delay = Math.min(
      this.baseDelay * Math.pow(2, this.reconnectAttempts),
      this.socketOptions.reconnectionDelayMax
    );

    this.reconnectAttempts++;

    console.info(
      `Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts})`
    );

    setTimeout(() => {
      if (this.currentUserId && this.apiBaseUrl) {
        this.socket?.close();
        this.socket = null;
        this.initializeConnection(this.apiBaseUrl, this.currentUserId);
      }
    }, delay);
  };

  private emitConnectionStatus = (connected: boolean) => {
    // You can implement custom logic here to notify your application about connection status
    const event = new CustomEvent("socketConnectionStatus", {
      detail: { connected },
    });
    window.dispatchEvent(event);
  };

  provideUserId = (userId: string) => {
    if (!this.socket) {
      console.warn("Socket not initialized when providing user ID");
      return;
    }

    try {
      this.socket.emit("joinRoom", userId);
      console.info("Joined room for user:", userId);
    } catch (error) {
      console.error("Error joining room:", error);
    }
  };

  disconnectSocket = () => {
    try {
      if (this.socket) {
        this.socket.removeAllListeners();
        this.socket.disconnect();
        this.socket = null;
        this.currentUserId = null;
        this.apiBaseUrl = null;
        this.reconnectAttempts = 0;
        console.info("Socket disconnected successfully");
      }
    } catch (error) {
      console.error("Error disconnecting socket:", error);
    }
  };

  // Method to check current connection status
  isConnected = (): boolean => {
    return !!this.socket?.connected;
  };

  // Method to manually trigger reconnection
  reconnect = () => {
    if (this.currentUserId && this.apiBaseUrl) {
      this.handleReconnection();
    } else {
      console.warn("Cannot reconnect: missing user ID or API URL");
    }
  };
}

export const notificationSocket = new NotificationSocketClass();
