import React, { useEffect, useState } from "react";
import DropDownFilter from "./DropDownFilter";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { Input } from "@heathmont/moon-core-tw";
import {
  EMPLOYEES_FILTER,
  LEAVES_FILTER,
  PATIENTS_FILTER,
  REQUEST_TIMEOFF_FILTER,
} from "../../../enums/branch-admin/viewsFilter.enums";
import { FILTER_SELECT } from "../../../enums/branch-admin/filters.enums";
import FilterMonth from "./FilterMonth";
import FilterYear from "./FilterYear";
import ValueDropDown from "./ValueDropDown";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
interface Options {
  name: string;
}
interface FilterElements {
  id: number;
}
interface Props {
  index: number;
  filter: FilterElements;
  options: Options[];
  match: Options[];
  filtersArray: IFilterParameters[];
  setFiltersArray: (filtersDataArray: IFilterParameters[]) => void;
  selectedFilter: string;
  setSelectedFilter: (filterOptions: string) => void;
  selectedOption: string;
  setSelectedOption: (filterOptions: string) => void;
  handleFilterValue: (
    id: number,
    value: string | Date | string[],
    isDate?: boolean,
    isRangeDate?: boolean
  ) => void;
  setFilterValue: (value: string | Date) => void;
  idPatternErrMsg: string;
  setIdPatternErrMsg: (msg: string) => void;
}

const allThreeWithInput = [
  PATIENTS_FILTER.ID,
  PATIENTS_FILTER.PATIENT_NAME,
  EMPLOYEES_FILTER.ID,
  EMPLOYEES_FILTER.EMPLOYEE_NAME,
  REQUEST_TIMEOFF_FILTER.ID,
  REQUEST_TIMEOFF_FILTER.EMPLOYEE_NAME,
  LEAVES_FILTER.ID,
  LEAVES_FILTER.EMPLOYEE_NAME,
];
const allThreeWithDate = [
  EMPLOYEES_FILTER.JOINING_DATE,
  PATIENTS_FILTER.ADMISSION_DATE,
  PATIENTS_FILTER.DISCHARGE_DATE,
  REQUEST_TIMEOFF_FILTER.DATE,
];
const inputOnly = [
  EMPLOYEES_FILTER.DESIGNATION,
  EMPLOYEES_FILTER.ROLE,
  EMPLOYEES_FILTER.APPOINTED_SECTION,
  PATIENTS_FILTER.ALLOCATED_SECTION,
  PATIENTS_FILTER.ASSIGNED_ROOM,
  REQUEST_TIMEOFF_FILTER.SECTION,
  LEAVES_FILTER.EMPLOYEE_SECTION,
];
const valueDropDownOnly = [
  EMPLOYEES_FILTER.SENIORITY,
  PATIENTS_FILTER.NEEDY_PROFILE,
  REQUEST_TIMEOFF_FILTER.STATUS,
  LEAVES_FILTER.LEAVE_TYPE,
  LEAVES_FILTER.STATUS,
  REQUEST_TIMEOFF_FILTER.MONTH,
  REQUEST_TIMEOFF_FILTER.YEAR,
];
const leavesDates = [
  // REQUEST_TIMEOFF_FILTER.LEAVES_FROM_TO_DATE,
  LEAVES_FILTER.LEAVES_FROM_TO_DATE,
];

const exactEmpIdRegex = "^EMP\\d{3}$";
const exactPatIdRegex = "^PAT\\d{3}$";
const noInitSpaceRegex = "^[^ ]+";

const FilterFields: React.FC<Props> = ({
  index,
  filter,
  options,
  match,
  filtersArray,
  setFiltersArray,
  selectedFilter,
  setSelectedFilter,
  selectedOption,
  setSelectedOption,
  handleFilterValue,
  setFilterValue,
  idPatternErrMsg,
  setIdPatternErrMsg,
}) => {
  const [isForAllThreeWithInput, setIsForAllThreeWithInput] = useState(false);
  const [isForAllThreeWithDate, setIsForAllThreeWithDate] = useState(false);
  const [isForInputOnly, setIsForInputOnly] = useState(false);
  const [isForValueDropownOnly, setIsForValueDropownOnly] = useState(false);
  const [isMonthChanged, setIsMonthChanged] = useState<string>("");
  const [isForLeavesDate, setIsForLeavesDate] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startFromDate, endDate] = dateRange;

  useEffect(() => {
    // const tempArrayData = [...filtersArray];
    // tempArrayData[index].filter = "";
    // setFiltersArray(tempArrayData);
    // handleFilterValue(index, "");

    setIsForAllThreeWithInput(
      allThreeWithInput.includes(filtersArray[index]?.option as string)
    );
    setIsForAllThreeWithDate(
      allThreeWithDate.includes(filtersArray[index]?.option as string)
    );
    setIsForInputOnly(
      inputOnly.includes(filtersArray[index]?.option as string)
    );
    setIsForValueDropownOnly(
      valueDropDownOnly.includes(filtersArray[index]?.option as string)
    );
    setIsForLeavesDate(
      leavesDates.includes(filtersArray[index]?.option as string)
    );
    setIdPatternErrMsg("");

    // if (
    //   !filtersArray[index]?.value?.includes("Invalid date") &&
    //   Array.isArray(filtersArray[index]?.value)
    // ) {
    //   const stringDate = filtersArray[index].value?.toString();
    //   const startDate = stringDate?.split(",")[0];
    //   const endDate = stringDate?.split(",")[1];

    //   if (startDate && endDate) setRangeDateValue(`${startDate} - ${endDate}`);
    // }
    if (
      filtersArray[index]?.value &&
      filtersArray[index]?.filter === "By Date"
    ) {
      setStartDate(moment(filtersArray[index]?.value).toDate());
      console.log("object");
    }
  }, [index, filtersArray[index].option]);

  // useEffect(() => {
  //   setStartDate(undefined);
  //   console.log("change value on change of filter");
  //   handleFilterValue(index, "");
  // }, [filtersArray[index]?.filter]);

  const handlePlaceHolders = () => {
    const option = filtersArray[index]?.option as string;

    const placeholders = {
      [PATIENTS_FILTER.ID]: "Enter Patient Id",
      [PATIENTS_FILTER.PATIENT_NAME]: "Enter Patient Name",
      [EMPLOYEES_FILTER.ID]: "Enter Employee Id",
      [EMPLOYEES_FILTER.EMPLOYEE_NAME]: "Enter Employee Name",
      [REQUEST_TIMEOFF_FILTER.ID]: "Enter Employee Id",
      [REQUEST_TIMEOFF_FILTER.EMPLOYEE_NAME]: "Enter Employee Name",
      [LEAVES_FILTER.ID]: "Enter Employee Id",
      [LEAVES_FILTER.EMPLOYEE_NAME]: "Enter Employee Name",
      [EMPLOYEES_FILTER.DESIGNATION]: "Enter Employee Designation",
      [EMPLOYEES_FILTER.ROLE]: "Enter Employee Role",
      [EMPLOYEES_FILTER.APPOINTED_SECTION]: "Enter Appointed Section",
      [PATIENTS_FILTER.ALLOCATED_SECTION]: "Enter Allocated Section",
      [PATIENTS_FILTER.ASSIGNED_ROOM]: "Enter Assigned Room",
      [REQUEST_TIMEOFF_FILTER.SECTION]: "Enter Employee Section",
      [LEAVES_FILTER.EMPLOYEE_SECTION]: "Enter Employee Section",
    };

    return placeholders[option] || "";
  };
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\S.*$/.test(event.target.value)) {
      handleFilterValue(index, event?.target?.value);
      setFilterValue(event?.target?.value);
      setIdPatternErrMsg("");
    } else {
      handleFilterValue(index, "");
      setFilterValue("");
    }
  };

  const handleBlur = (value: string) => {
    const regex =
      // (filtersArray[index]?.option === "Employee Id" ||
      //   filtersArray[index]?.option === "Patient Id") &&
      filtersArray[index]?.filter === "Contains"
        ? noInitSpaceRegex //"^S.*$"
        : filtersArray[index]?.option === "Patient Id"
        ? exactPatIdRegex
        : filtersArray[index]?.option === "Employee Id"
        ? exactEmpIdRegex
        : ""; //"^EMP\\d{3}$"

    if (!value.match(regex)) {
      setIdPatternErrMsg("Please enter a valid Id Pattern");
    }
  };

  const handleRegexForId = () => {
    const idPattern: string =
      filtersArray[index]?.filter === "Contains"
        ? noInitSpaceRegex //"^S.*$"
        : filtersArray[index]?.option === "Patient Id"
        ? exactPatIdRegex
        : filtersArray[index]?.option === "Employee Id"
        ? exactEmpIdRegex
        : "";

    return idPattern;
  };
  console.log("err msg: ", idPatternErrMsg);
  return (
    <>
      {/* <div key={filter?.id} className="flex flex-col gap-2 mb-2"> */}
      {isForAllThreeWithInput && (
        <>
          <div className="w-full">
            <DropDownFilter
              id={index}
              type="filter"
              data={match}
              filtersArray={filtersArray as IFilterParameters[]}
              setFiltersArray={setFiltersArray}
              setSelectedFilter={setSelectedFilter}
              setSelectedOption={setSelectedOption}
              handleFilterValue={handleFilterValue}
            />
          </div>
          {/* {
          !(
            selectedFilter === FILTER_SELECT.EMP_NAME[1].name ||
            selectedFilter === FILTER_SELECT.EMP_NAME[2].name
          ) && ( */}
          <div className="w-full">
            <Input
              aria-label="default"
              className="bg-[#F3F3F8] z-0"
              pattern={handleRegexForId()}
              placeholder={handlePlaceHolders()}
              onChange={(event) => {
                handleInput(event);
              }}
              value={filtersArray[index]?.value as string}
              disabled={
                filtersArray[index]?.option === "" ||
                filtersArray[index]?.filter === ""
                  ? true
                  : false
              }
              onBlur={(e) => handleBlur(e?.target?.value)}
            />
          </div>
          {/* )} */}
          {idPatternErrMsg !== "" ? (
            <div className="text-red-500 justify-center">{idPatternErrMsg}</div>
          ) : (
            <></>
          )}
        </>
      )}
      {isForAllThreeWithDate && (
        <>
          <div className="w-full">
            <DropDownFilter
              id={index}
              type="filter"
              data={match}
              filtersArray={filtersArray as IFilterParameters[]}
              setStartDate={setStartDate}
              setFiltersArray={setFiltersArray}
              setSelectedFilter={setSelectedFilter}
              setSelectedOption={setSelectedOption}
              handleFilterValue={handleFilterValue}
            />
          </div>
          {filtersArray[index]?.filter ===
          FILTER_SELECT.PAT_ADD_DIS_DATE[0].name ? (
            // By Date
            <div className="w-full">
              <ReactDatePicker
                selected={
                  startDate
                  // || moment(filtersArray[index]?.value).toDate()
                }
                placeholderText="(MM/DD/YYYY)"
                onChange={(date: Date) => {
                  setStartDate(date);
                  handleFilterValue(index, date, true);
                  setFilterValue(date);
                }}
                className="w-full  border-beerus border px-3 py-2 z-10 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]"
              />
            </div>
          ) : filtersArray[index]?.filter ===
            FILTER_SELECT.PAT_ADD_DIS_DATE[1].name ? (
            // By Month and Year
            //////////// Instead of two inputs add two drop down in same line first for Months and second for Years //////////////////
            <>
              <div className="flex -mx-3">
                <div className="w-1/2 px-3">
                  <FilterMonth
                    id={index}
                    filtersArray={filtersArray}
                    setFiltersArray={setFiltersArray}
                    setIsMonthChanged={setIsMonthChanged}
                  />
                </div>
                <div className="w-1/2 px-3">
                  <FilterYear
                    id={index}
                    filtersArray={filtersArray}
                    setFiltersArray={setFiltersArray}
                    isMonthChanged={isMonthChanged}
                    isMonthSelected={true}
                  />
                </div>
              </div>
            </>
          ) : filtersArray[index]?.filter ===
            FILTER_SELECT.PAT_ADD_DIS_DATE[2].name ? (
            // By Year
            ////////// Just add a single drop down to select Years ///////////
            <div className="w-full">
              <FilterYear
                id={index}
                filtersArray={filtersArray}
                setFiltersArray={setFiltersArray}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {isForInputOnly && (
        <div className="w-full">
          <Input
            aria-label="default"
            className="bg-[#F3F3F8] z-0"
            pattern="^\S.*$"
            placeholder={handlePlaceHolders()}
            onChange={(event) => {
              handleInput(event);
            }}
            value={filtersArray[index]?.value as string}
            // disabled={
            //   filtersArray[index]?.option === "" ||
            //   filtersArray[index]?.filter === ""
            //     ? true
            //     : false}
          />
        </div>
      )}
      {isForValueDropownOnly &&
        //// This part of the code is commented as in RTO we are not using filters for only month and year. If you want to use them in future un-comment the code.
        //   REQUEST_TIMEOFF_FILTER.MONTH === filtersArray[index]?.option ? (
        //   /////////// Change the this input into drop down for Months //////////////
        //   <div className="w-full">
        //     <FilterMonth
        //       id={index}
        //       filtersArray={filtersArray}
        //       setFiltersArray={setFiltersArray}
        //       setIsMonthChanged = {setIsMonthChanged}
        //     />
        //   </div>
        // ) : REQUEST_TIMEOFF_FILTER.YEAR === filtersArray[index]?.option ? (
        //   ///////////////// Change this input into drop down for Years ////////////////
        //   <div className="w-full">
        //     <FilterYear
        //       id={index}
        //       filtersArray={filtersArray}
        //       setFiltersArray={setFiltersArray}
        //     />
        //   </div>
        // ) :
        (EMPLOYEES_FILTER.SENIORITY === filtersArray[index]?.option ? (
          /////// A drop down will be added for seniority level filter select////
          <div className="w-full">
            <ValueDropDown
              id={index}
              type="seniority_level"
              filtersArray={filtersArray}
              setFiltersArray={setFiltersArray}
            />
          </div>
        ) : PATIENTS_FILTER.NEEDY_PROFILE === filtersArray[index]?.option ? (
          /////// A drop down will be added for Needy Profile filter select////

          <div className="w-full">
            <ValueDropDown
              id={index}
              type="needy_profile"
              filtersArray={filtersArray}
              setFiltersArray={setFiltersArray}
            />
          </div>
        ) : REQUEST_TIMEOFF_FILTER.STATUS === filtersArray[index]?.option ? (
          /////// A drop down will be added for RTO Status filter select////

          <div className="w-full">
            <ValueDropDown
              id={index}
              type="RTO_status"
              filtersArray={filtersArray}
              setFiltersArray={setFiltersArray}
            />
          </div>
        ) : LEAVES_FILTER.LEAVE_TYPE === filtersArray[index]?.option ? (
          /////// A drop down will be added for Leave type filter select////

          <div className="w-full">
            <ValueDropDown
              id={index}
              type="leave_type"
              filtersArray={filtersArray}
              setFiltersArray={setFiltersArray}
            />
          </div>
        ) : (
          LEAVES_FILTER.STATUS === filtersArray[index]?.option && (
            /////// A drop down will be added for Leaves Status filter select////

            <div className="w-full">
              <ValueDropDown
                id={index}
                type="leave_status"
                filtersArray={filtersArray}
                setFiltersArray={setFiltersArray}
              />
            </div>
          )
        ))}

      {isForLeavesDate && (
        //Have to change this input for the date range i.e. for From Date - To Date as discussed
        <div className="w-full">
          <ReactDatePicker
            selectsRange={true}
            startDate={startFromDate}
            endDate={endDate}
            placeholderText="Select dates"
            // value={rangeDateValue}
            onChange={(date: any) => {
              setDateRange(date);
              handleFilterValue(index, date, undefined, true);
              setFilterValue(date);
            }}
            isClearable={true}
            className="w-full  border-beerus border px-3 py-2 z-50 rounded-lg font-normal text-moon-14 justify-between inline-flex items-center bg-[#F3F3F8]"
          />
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default FilterFields;
