export const SENIORITY_LEVEL = {
  senior: "senior",
  junior: "junior",
  intern: "intern",
};

export const ROLE = {
  NURSE: "nurse",
  HCA: "HCA",
  SENIOR_NURSE: "senior nurse",
  BRANCH_ADMIN: "branch admin",
  NURSE_MANAGER: "nurse manager",
  NURSE_DIRECTOR: "nurse director",
  OPERATION_MANAGER: "operation manager",
};
