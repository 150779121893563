import React, { useEffect, useState } from "react";
import {
  activateEmpSvc,
  deActivateEmpSvc,
  elevateEmpRole,
  getEmployeesView,
} from "../../../services/branch-admin-services/views.service";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import Table from "../../../Components/branch-admin/ViewTable/table";
import { IEmployees } from "../../../types/branch-admin-interfaces/IEmployees.interface";
import { ITableDataResponse } from "../../../types/ITableData.interface";
import { IFilterParameters } from "../../../types/branch-admin-interfaces/IFilterParameters.interface";
import { Button, IconButton, Switch } from "@heathmont/moon-core-tw";
import ConfirmationModal from "../../../Components/confirmation-modal/confirmationModal";
import EmployeeCreateEditModal from "../../../Components/branch-admin/ui/EmployeeCreateEditModal";
import { getSections } from "../../../services/branch-admin-services/section.service";
import {
  deleteEmployee,
  postEmployee,
  updateEmployee,
} from "../../../services/branch-admin-services/employee.service";
import { RootState, store } from "../../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import { GenericDelete, GenericEdit } from "@heathmont/moon-icons-tw";
import ConfirmDeleteModal from "../../super-admin/settings/hospital/widgets/ConfirmationModal";
import { useSelector } from "react-redux";

interface Props {
  dataImported: boolean;
  searchQuery: string;
  filtersArray: IFilterParameters[];
  isApplyFilter: boolean;
}

interface EmployeeData {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  joiningDate: Date | null;
  seniorityLevel: string;
  designation: string;
  role: string;
  hospital: string;
  branch: string;
  assignedSection: string;
  department: string | undefined;
  gender: string | undefined;
  address: string;
  isActive: boolean;
  temporaryRole: string;
  password?: string;
}

const EmployeesView: React.FC<Props> = ({
  dataImported,
  searchQuery,
  filtersArray,
  isApplyFilter,
}) => {
  const columnHelper = createColumnHelper<IEmployees>();
  const user = useSelector((state: RootState) => state.auth.User);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isActiveEmp, setIsActiveEmp] = useState<boolean>(true);
  const [isRoleElevated, setIsRoleElevated] = useState<boolean>(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [employeeData, setEmployeeData] = useState({
    employeeId: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    joiningDate: new Date(),
    seniorityLevel: "",
    designation: "",
    role: "",
    hospital: "",
    branch: "",
    assignedSection: "",
    department: "",
    gender: "",
    address: "",
    isActive: true,
    temporaryRole: "",
    password: "",
  } as EmployeeData);
  const [empData, setEmpData] = useState<IEmployees>();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const columns = [
    columnHelper.accessor("employeeId", {
      header: () => <span>ID</span>,
      cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
    }),
    columnHelper.accessor("employeeName", {
      id: "employeeName",
      header: () => "Employee Name",
      cell: (info) => (
        <span className="truncate max-w-[200px] capitalize">
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor("role", {
      enableSorting: false,

      header: () => "Role",
      cell: (info) => (
        <span className="text-gray-400 capitalize">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("designation", {
      enableSorting: false,

      header: () => "Designation",
      cell: (info) => (
        <span className="text-gray-400 capitalize">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("contactNo", {
      enableSorting: false,

      header: () => <span>Phone#</span>,
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("email", {
      enableSorting: false,

      header: "Email Address",
      cell: (info) => (
        <span className="text-gray-400 first-line:truncate max-w-[300px]">
          {info.renderValue()}
        </span>
      ),
    }),
    columnHelper.accessor("joiningDate", {
      enableSorting: false,

      header: "Joining Date",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("department", {
      enableSorting: false,

      header: "Section",
      cell: (info) => (
        <span className="text-gray-400">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("seniorityLevel", {
      enableSorting: false,

      header: "Seniority Level",
      cell: (info) => (
        <span className="text-gray-400 capitalize">{info.renderValue()}</span>
      ),
    }),
    columnHelper.accessor("isActive", {
      enableSorting: false,

      header: "Active",
      cell: (info) => (
        // <span className="text-gray-400">{info.renderValue()}</span>
        <Switch
          checked={info.renderValue() as boolean}
          onChange={() => {
            handleActiveStatus(info?.row?.original);
          }}
          size="2xs"
          className="bg-dodoria moon-checked:bg-roshi"
          aria-label="Switch with custom styles"
        />
      ),
    }),
    columnHelper.accessor("temporaryRole", {
      enableSorting: false,

      header: "Elevate Role",
      cell: (info) => {
        // Use ternary operator to conditionally render the Switch or "Not Applicable"
        return info?.row?.original?.role === "nurse" ||
          info?.row?.original?.role === "senior nurse" ||
          info?.row?.original?.role === "nurse director" ? (
          <Switch
            checked={isRoleChecked(info.renderValue() as string)}
            onChange={() => handleRoleChange(info?.row?.original)}
            size="2xs"
            className="bg-dodoria moon-checked:bg-roshi"
            aria-label="Switch with custom styles"
          />
        ) : (
          "N/A"
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      enableSorting: false,
      header: "Actions",
      cell: (info) => (
        <span className="flex justify-center">
          <IconButton
            variant="ghost"
            icon={<GenericEdit />}
            onClick={() => {
              editHandler(info.row.original);
            }}
          />
          {info.row.original._id !== user?.id && (
            <IconButton
              variant="ghost"
              icon={<GenericDelete />}
              onClick={() => {
                openConfirmationModal({
                  title: "Confirm Delete Employee",
                  actionFor: "",
                  message: (
                    <p className="text-moon-18 mb-[28px]">
                      {" "}
                      The Employee{" "}
                      <strong className="font-semibold ">
                        {info.row.original?.employeeName}
                      </strong>
                      <br />
                      will be deleted
                    </p>
                  ),
                  confirmAction: "Delete",
                  id: {
                    _id: info.row.original._id,
                  },
                });
              }}
            />
          )}
        </span>
      ),
    }),
  ];

  const openConfirmationModal = (info: any) => {
    setConfirmationData(info);
    setIsOpenConfirm(true);
  };

  const handleConfirmAction = async () => {
    const res: any = await deleteEmployee(confirmationData?.id?._id);
    if (!res?.err) {
      store.dispatch(setSnackBar("success"));
      store.dispatch(setToastMessage("Employee deleted successfuly!"));
      await getEmployeesData(1);
    } else {
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("An error occured. Try again later."));
    }
    setIsOpenConfirm(false);
  };

  function formatDate(date: Date) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  function dateStringToDate(dateString: string) {
    const parts = dateString.split("/"); // Split the string by "/"
    const day = parseInt(parts[0], 10); // Convert day part to integer
    const month = parseInt(parts[1], 10) - 1; // Convert month part to integer (subtract 1 as months are zero-indexed)
    const year = parseInt(parts[2], 10); // Convert year part to integer

    // Create Date object
    const dateObject = new Date(year, month, day);

    return dateObject;
  }

  const editHandler = (info: any) => {
    setEdit(true);
    let editData = { ...info };
    editData.joiningDate = dateStringToDate(editData.joiningDate);
    setEmployeeData(editData as EmployeeData);
    setIsOpen(true);
  };

  const [data, setData] = React.useState(() => []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let timeoutId: NodeJS.Timeout | null = null;

  const [employeesViewRes, setEmployeesViewRes] =
    useState<ITableDataResponse>();

  const handleActiveStatus = async (row: IEmployees) => {
    row.isActive
      ? await deActivateEmpSvc(row?._id)
      : await activateEmpSvc(row?._id);
    setIsActiveEmp(!isActiveEmp);
  };
  const isRoleChecked = (role: string) => {
    return role?.includes("nurse manager") || role?.includes("branch admin");
  };
  const handleRoleChange = (row: IEmployees) => {
    setEmpData(row);
    setIsOpenConfirmation(true);

    // if (row?.temporaryRole === "") {
    //   setIsOpenConfirmation(true);
    //   console.log("when dont have temp role");
    // handleElevateEmpRole(row?._id, selectElevationRole(row?.designation));
    // } else {
    //   console.log("when have temp role");
    // handleElevateEmpRole(row?._id, "");
    // }
  };

  const handleElevateEmpRole = async (emp: IEmployees) => {
    await elevateEmpRole(emp?._id, selectElevationRole(emp));
    setIsRoleElevated(!isRoleElevated);
  };

  const selectElevationRole = (emp: IEmployees) => {
    if (emp?.temporaryRole !== "") return "";
    switch (emp?.role) {
      case "nurse":
      case "senior nurse":
        return "nurse manager";
      case "nurse director":
        return "branch admin";
      default:
        return "";
    }
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const getEmployeesData = async (pageChange: number, searchQuery?: string) => {
    const params = {
      pageChange: pageChange,
      limit: 50,
      search: searchQuery,
      filterParams: filtersArray,
    };
    setIsLoading(true);
    const res = await getEmployeesView(params);
    setIsLoading(false);
    setData(res?.records);
    setEmployeesViewRes(res);
  };
  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getEmployeesData(1, searchQuery);
      }, 1000);
    } else {
      getEmployeesData(1);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dataImported, searchQuery, isApplyFilter, isActiveEmp, isRoleElevated]);

  const [sections, setSections] = useState([]);

  const getSectionsData = async () => {
    const response: any = await getSections();
    if (!response.err) {
      setSections(response?.sections);
    }
  };

  console.log(employeeData, "employee data");

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    if (!edit) {
      let data: any = { ...employeeData };
      data.joiningDate = formatDate(data.joiningDate as Date);
      data.employeeName = employeeData.firstName + " " + employeeData.lastName;
      delete data.temporaryRole;
      const res: any = await postEmployee(data);
      if (!res.err) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Employee Added Successfully"));
        await getEmployeesData(1);
        closeModal();
      } else if (res.err.error) {
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage(res.err.error));
      }
    } else {
      let data: any = { ...employeeData };
      data.employeeName = employeeData.firstName + " " + employeeData.lastName;
      data.joiningDate = formatDate(data.joiningDate as Date);
      const res: any = await updateEmployee(data);
      if (!res.err) {
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Employee Edit Successfully"));
        await getEmployeesData(1);
        closeModal();
      } else if (res.err.error) {
        store.dispatch(setSnackBar("error"));
        store.dispatch(setToastMessage(res.err.error));
      }
    }
    // Implement your form submission logic here
  };
  useEffect(() => {
    getSectionsData();
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    setEdit(false);
    setEmployeeData({
      employeeId: "",
      firstName: "",
      lastName: "",
      email: "",
      contactNo: "",
      joiningDate: new Date(),
      seniorityLevel: "",
      designation: "",
      hospital: "",
      branch: "",
      assignedSection: "",
      department: "",
      gender: "",
      address: "",
      isActive: true,
      temporaryRole: "",
      password: "",
    } as EmployeeData);
  };

  return (
    <div>
      <div className="w-full px-[16px] text-right">
        <div className="flex items-center justify-end gap-2 py-1">
          <Button
            variant="tertiary"
            className="ml-aut text-white"
            onClick={openModal}
          >
            Add Employee
          </Button>
        </div>
      </div>
      <ConfirmationModal
        isOpenConfirmation={isOpenConfirmation}
        setIsOpenConfirmation={setIsOpenConfirmation}
        message={`Are you sure you want to change the temporary role of ${empData?.employeeName}?`}
        actionFunction={handleElevateEmpRole}
        sendBackParam={empData as IEmployees}
      />
      <EmployeeCreateEditModal
        sections={sections}
        isOpen={isOpen}
        closeModal={closeModal}
        setEmployeeData={setEmployeeData}
        handleSubmit={handleSubmit}
        edit={edit}
        employeeData={employeeData}
      />
      {!isLoading && (
        <Table
          header={true}
          table={table}
          tableData={employeesViewRes as ITableDataResponse}
          handlePageChange={getEmployeesData}
        />
      )}
      <ConfirmDeleteModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </div>
  );
};

export default EmployeesView;
