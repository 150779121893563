import { Button, Chip, Tabs } from "@heathmont/moon-core-tw";

import { Input } from "@heathmont/moon-core-tw";
import { GenericSearch } from "@heathmont/moon-icons-tw";
import PatientDetailTable from "./PatientDetailTable";
import React, { useEffect, useRef, useState } from "react";
import { IPatientJob } from "../../../types/operation-manager-interfaces/IPatientJob.interface";
import { IEmployeeJob } from "../../../types/operation-manager-interfaces/IEmployeeJob.interface";
import { IJobHistory } from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import EmployeeDetailTable from "./EmployeeDetailTable";
import ConfirmDeleteModal from "../../super-admin/settings/hospital/widgets/ConfirmationModal";
import {
  deleteEmployee,
  deleteLeave,
  deletePatient,
  submitJob,
  updateJobEmployee,
  updateJobPatient,
} from "../../../services/operation-manager-services/job.service";
import EmployeeCreateEditModal from "../../../Components/branch-admin/ui/EmployeeCreateEditModal";
import { getSections } from "../../../services/branch-admin-services/section.service";
import { ISection } from "../../../types/branch-admin-interfaces/ISection.interface";
import moment from "moment";
import PatientEditCreateModal from "../../../Components/branch-admin/ui/PatientEditCreateModal";
import { useNavigate } from "react-router-dom";
import LeaveDetailTable from "./LeaveDetailTable";
import { ILeaves } from "../../../types/branch-admin-interfaces/ILeaves.interface";

interface IPatientsTab {
  data: IJobHistory;
  getHistory: (page: number, search: string, tab?: number) => void;
  sections: ISection[];
}
const PatientTabs = ({ data, getHistory, sections }: IPatientsTab) => {
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [search, setSearch] = useState("");
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [confirmationData, setConfirmationData] = useState<any>(null);
  const [deletedId, setDeletedId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPatient, setIsOpenPatient] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [employeeData, setEmployeeData] = useState({
    employeeId: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    joiningDate: new Date(),
    seniorityLevel: "",
    designation: "",
    role: "",
    hospital: "",
    branch: "",
    assignedSection: "",
    department: "",
    gender: "",
    address: "",
    isActive: true,
    temporaryRole: "",
    password: "",
  });
  const [profileData, setProfileData] = useState({
    _id: "",
    batchId: "",
    patientId: "",
    status: "Active",
    critical: false,
    patientName: "",
    admissionDate: new Date(),
    dischargeDate: new Date(),
    sectionId: "",
    allocatedSection: "",
    assignedRoom: "",
    referredDoctor: "",
    needyProfile: "Low",
  });

  const tabs = ["All", "New", "Existing"];

  const handleConfirmAction = async () => {
    const response =
      data.jobType === "Patients"
        ? await deletePatient(deletedId)
        : data.jobType === "Employees"
        ? await deleteEmployee(deletedId)
        : await deleteLeave(deletedId);
    if (response?.res) {
      getHistory(1, "");
    }
  };

  const handleDeletePatient = (data: IPatientJob) => {
    setConfirmationData({
      title: "Confirm Delete Patient",
      actionFor: "Patient",
      message: (
        <p className="text-moon-18 mb-[28px]">
          {" "}
          The Patient{" "}
          <strong className="font-semibold ">{data?.patientName}</strong>
          <br />
          will be deleted
        </p>
      ),
      confirmAction: "Delete",
    });
    setDeletedId(data._id);
    setIsOpenConfirm(true);
  };

  const handleDeleteEmployee = (data: IEmployeeJob) => {
    setConfirmationData({
      title: "Confirm Delete Employee",
      actionFor: "Employee",
      message: (
        <p className="text-moon-18 mb-[28px]">
          {" "}
          The Employee{" "}
          <strong className="font-semibold ">{data?.employeeName}</strong>
          <br />
          will be deleted
        </p>
      ),
      confirmAction: "Delete",
    });
    setDeletedId(data._id);
    setIsOpenConfirm(true);
  };
  const handleDeleteLeave = (data: ILeaves) => {
    setConfirmationData({
      title: "Confirm Delete Leaves",
      actionFor: "Employee",
      message: (
        <p className="text-moon-18 mb-[28px]">
          {" "}
          The Leaves of{" "}
          <strong className="font-semibold ">{data.employeeName}</strong>
          <br />
          will be deleted
        </p>
      ),
      confirmAction: "Delete",
    });
    setDeletedId(data._id || "");
    setIsOpenConfirm(true);
  };

  const handleEmployeeEdit = (data: IEmployeeJob) => {
    setEmployeeData({
      ...data,
      joiningDate: moment(new Date(data.joiningDate)).isValid()
        ? new Date(data.joiningDate)
        : new Date(),
      isActive: true,
      temporaryRole: data.temporaryRole || "",
    });
    setValidationErrors(data.validationErrors);
    setIsOpen(true);
  };

  const handleUpdateEmployee = async () => {
    const response = await updateJobEmployee({
      ...employeeData,
      joiningDate: moment(employeeData.joiningDate).format("DD/MM/YYYY"),
    });
    if (response?.res) {
      getHistory(data.employees?.currentPage || 1, search);
      setIsOpen(false);
    }
  };

  const handleUpdatePatient = async () => {
    const response = await updateJobPatient({
      ...profileData,
      admissionDate: moment(profileData.admissionDate).format("MM/DD/YYYY"),
      dischargeDate: moment(profileData.dischargeDate).format("MM/DD/YYYY"),
      needyProfile: profileData.needyProfile,
    });
    if (response?.res) {
      getHistory(data.employees?.currentPage || 1, search);
      setIsOpenPatient(false);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    const timer = setTimeout(() => {
      getHistory(1, search);
    }, 500);

    // Cleanup timeout on each searchTerm change
    return () => clearTimeout(timer);
  }, [search]);

  const handlePatientEdit = (id: IPatientJob) => {
    setProfileData({
      ...id,
      patientId: id.patientId || "",
      admissionDate: moment(new Date(id.admissionDate || "")).isValid()
        ? new Date(id.admissionDate || "")
        : new Date(),
      dischargeDate: moment(new Date(id.dischargeDate || "")).isValid()
        ? new Date(id.dischargeDate || "")
        : new Date(),
      status: id.status || "",
      critical: id.critical || false,
      patientName: id.patientName || "",
      allocatedSection: id.allocatedSection || "",
      assignedRoom: id.assignedRoom || "",
      needyProfile: id.needyProfile || "Low",
      referredDoctor: id.referredDoctor || "",
      sectionId: id.sectionId || "",
    });
    setValidationErrors(id.validationErrors || []);
    setIsOpenPatient(true);
  };

  const submitJobDetails = async () => {
    const response = await submitJob(data._id);
    if (response?.res) {
      navigate("/");
    }
  };

  const handleTabChange = async (tab: number) => {
    getHistory(1, search, tab);
  };

  return (
    <>
      <Tabs onChange={handleTabChange}>
        <Tabs.List className="w-full bg-hit items-start justify-start">
          <div className="sm:px-7 px-4  flex">
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab}
                className={({ selected }: { selected: boolean }) =>
                  `hover:text-white after:bg-white text-[#88D0E2] ${
                    selected && "text-white"
                  }`
                }
              >
                {tab}
              </Tabs.Tab>
            ))}
          </div>
        </Tabs.List>
        <Tabs.Panels>
          <Tabs.Panel>
            <div className="px-6 py-3 grid grid-cols-2 items-center gap-4">
              <div className="relative">
                <Input
                  aria-label="default"
                  placeholder={`Search ${data.jobType}`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-sm pl-9"
                  size="lg"
                />
                <i className="absolute top-1/2 left-2 text-moon-24 -translate-y-1/2 z-10">
                  <GenericSearch />
                </i>
              </div>
              <div className="text-end">
                {data.status === "Draft" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-chichi text-chichi hover:bg-chichi-10 hover:shadow-none
                   py-1 px-4 bg-chichi-10 rounded-lg`}
                    >
                      Draft
                    </Chip>
                    <Button variant="tertiary" onClick={submitJobDetails}>
                      Complete{" "}
                    </Button>
                  </div>
                )}
                {data.status === "Error" && (
                  <div className="flex items-center justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-dodoria text-dodoria hover:bg-dodoria-10 hover:shadow-none
                   py-1 px-4 bg-dodoria-10 rounded-lg`}
                    >
                      Error
                    </Chip>
                    {data.validationErrors.map((msg) => (
                      <p className="text-xs text-dodoria line-clamp-1">{msg}</p>
                    ))}
                  </div>
                )}

                {data.status === "Completed" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-roshi text-roshi hover:bg-roshi-10 hover:shadow-none
                   py-1 px-4 bg-roshi-10 rounded-lg`}
                    >
                      Completed
                    </Chip>
                  </div>
                )}
              </div>
            </div>
            {data.patients && data.patients.items && (
              <PatientDetailTable
                data={data.patients}
                onEdit={handlePatientEdit}
                onDelete={handleDeletePatient}
              />
            )}
            {data.employees && data.employees.items && (
              <EmployeeDetailTable
                data={data.employees}
                onEdit={handleEmployeeEdit}
                onDelete={handleDeleteEmployee}
              />
            )}
            {data.leaves && data.leaves.items && (
              <LeaveDetailTable
                data={data.leaves}
                onDelete={handleDeleteLeave}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel>
            <div className="px-6 py-3 grid grid-cols-2 items-center gap-4">
              <div className="relative">
                <Input
                  aria-label="default"
                  placeholder={`Search ${data.jobType}`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-sm pl-9"
                  size="lg"
                />
                <i className="absolute top-1/2 left-2 text-moon-24 -translate-y-1/2 z-10">
                  <GenericSearch />
                </i>
              </div>
              <div className="text-end">
                {data.status === "Draft" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-chichi text-chichi hover:bg-chichi-10 hover:shadow-none
                   py-1 px-4 bg-chichi-10 rounded-lg`}
                    >
                      Draft
                    </Chip>
                    <Button variant="tertiary" onClick={submitJobDetails}>
                      Complete{" "}
                    </Button>
                  </div>
                )}
                {data.status === "Error" && (
                  <div className="flex items-center justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-dodoria text-dodoria hover:bg-dodoria-10 hover:shadow-none
                   py-1 px-4 bg-dodoria-10 rounded-lg`}
                    >
                      Error
                    </Chip>
                    {data.validationErrors.map((msg) => (
                      <p className="text-xs text-dodoria line-clamp-1">{msg}</p>
                    ))}
                  </div>
                )}

                {data.status === "Completed" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-roshi text-roshi hover:bg-roshi-10 hover:shadow-none
                   py-1 px-4 bg-roshi-10 rounded-lg`}
                    >
                      Completed
                    </Chip>
                  </div>
                )}
              </div>
            </div>
            {data.patients && data.patients.items && (
              <PatientDetailTable
                data={data.patients}
                onEdit={handlePatientEdit}
                onDelete={handleDeletePatient}
              />
            )}
            {data.employees && data.employees.items && (
              <EmployeeDetailTable
                data={data.employees}
                onEdit={handleEmployeeEdit}
                onDelete={handleDeleteEmployee}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel>
            <div className="px-6 py-3 grid grid-cols-2 items-center gap-4">
              <div className="relative">
                <Input
                  aria-label="default"
                  placeholder={`Search ${data.jobType}`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-sm pl-9"
                  size="lg"
                />
                <i className="absolute top-1/2 left-2 text-moon-24 -translate-y-1/2 z-10">
                  <GenericSearch />
                </i>
              </div>
              <div className="text-end">
                {data.status === "Draft" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-chichi text-chichi hover:bg-chichi-10 hover:shadow-none
                   py-1 px-4 bg-chichi-10 rounded-lg`}
                    >
                      Draft
                    </Chip>
                    <Button variant="tertiary" onClick={submitJobDetails}>
                      Complete{" "}
                    </Button>
                  </div>
                )}
                {data.status === "Error" && (
                  <div className="flex items-center justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-dodoria text-dodoria hover:bg-dodoria-10 hover:shadow-none
                   py-1 px-4 bg-dodoria-10 rounded-lg`}
                    >
                      Error
                    </Chip>
                    {data.validationErrors.map((msg) => (
                      <p className="text-xs text-dodoria line-clamp-1">{msg}</p>
                    ))}
                  </div>
                )}

                {data.status === "Completed" && (
                  <div className="flex  justify-end gap-4">
                    <Chip
                      size="sm"
                      className={`border border-roshi text-roshi hover:bg-roshi-10 hover:shadow-none
                   py-1 px-4 bg-roshi-10 rounded-lg`}
                    >
                      Completed
                    </Chip>
                  </div>
                )}
              </div>
            </div>
            {data.patients && data.patients.items && (
              <PatientDetailTable
                data={data.patients}
                onEdit={handlePatientEdit}
                onDelete={handleDeletePatient}
              />
            )}
            {data.employees && data.employees.items && (
              <EmployeeDetailTable
                data={data.employees}
                onEdit={handleEmployeeEdit}
                onDelete={handleDeleteEmployee}
              />
            )}
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
      <ConfirmDeleteModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
      <EmployeeCreateEditModal
        sections={sections}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        setEmployeeData={setEmployeeData}
        handleSubmit={handleUpdateEmployee}
        edit={true}
        employeeData={employeeData}
        setValidationErrors={setValidationErrors}
        validationErrors={validationErrors}
      />
      {profileData._id && (
        <PatientEditCreateModal
          isOpen={isOpenPatient}
          closeModal={() => setIsOpenPatient(false)}
          profileData={profileData}
          setProfileData={setProfileData}
          handleSubmit={handleUpdatePatient}
          sections={sections}
          edit={true}
          setValidationErrors={setValidationErrors}
          validationErrors={validationErrors}
        />
      )}
    </>
  );
};

export default PatientTabs;
