import { Accordion, Button, Checkbox, Chip } from "@heathmont/moon-core-tw";
import { ControlsChevronDownSmall } from "@heathmont/moon-icons";
import React from "react";
import SimpleBar from "simplebar-react";
import { EmployeeCardData } from "../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import EmployeeCard from "../../Widgets/cards/card-employee/EmployeeCard";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";
import { getTextColor } from "../../../Colors/colorSetting";
import TrackingFilterAccordion from "../../Widgets/TrackingPoolWithAccordion/TrackingFilterAccordion";

interface Props {
  modalToExpand: string[];
  setModalToExpand: (intem: string[]) => void;
  areAvatarsVisible: boolean;
  filteredEmployees: EmployeeCardData[] | undefined;
  checkedItems: string[];
  setCheckedItems: (items: string[]) => void;
  checkedItemsBackup: string[];
  handleCheckboxChange: (empId: string, event: any) => void;
  selectedDate: any;
  month: IMonthDropDown;
  year: IYearDropDown;
  submitManSchedule: () => void;
}

const EmployeesDropDown: React.FC<Props> = ({
  modalToExpand,
  setModalToExpand,
  areAvatarsVisible,
  filteredEmployees,
  checkedItems,
  setCheckedItems,
  checkedItemsBackup,
  handleCheckboxChange,
  selectedDate,
  month,
  year,
  submitManSchedule,
}) => {
  let isAbleToSubmit =
    checkedItems.toString() === checkedItemsBackup.toString();

  return (
    <>
      <div className="flex justify-between flex-col flex-1 w-full">
        <div className="w-full">
          <Accordion
            // defaultValue={"item-2"}
            value={modalToExpand}
            onValueChange={setModalToExpand}
            className="gap-0 pt-1 pb-3"
            // singleOpen
          >
            <Accordion.Item value="item-1">
              <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-transparent 2xl:px-[25px] px-4 border-0">
                <Accordion.Button className="p-[0_!important]">
                  <span>
                    <strong>Allocated Employees</strong>
                  </span>
                  <ControlsChevronDownSmall className="text-trunks text-moon-24 transition duration-200 moon-open:text-bulma" />
                </Accordion.Button>
              </Accordion.Header>
              <Accordion.Content className="bg-transparent flex-col p-[0_!important">
                <TrackingFilterAccordion
                  filteredEmployees={filteredEmployees as EmployeeCardData[]}
                  alignment="aligned"
                  areAvatarsVisible={areAvatarsVisible}
                  checkedItems={checkedItems}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedDate={selectedDate}
                  month={month}
                  year={year}
                />
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="item-2">
              <Accordion.Header className="moon-open:[&_svg]:rotate-180 bg-transparent 2xl:px-[25px] px-4 font-bold">
                <Accordion.Button className="p-[0_!important]">
                  <span>
                    <strong>Unallocated Employees</strong>
                  </span>
                  <ControlsChevronDownSmall className="text-trunks text-moon-24 transition duration-200 moon-open:text-bulma" />
                </Accordion.Button>
              </Accordion.Header>
              <Accordion.Content className="bg-transparent flex-col p-[0_!important]">
                <TrackingFilterAccordion
                  filteredEmployees={filteredEmployees as EmployeeCardData[]}
                  alignment="not-aligned"
                  areAvatarsVisible={areAvatarsVisible}
                  checkedItems={checkedItems}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedDate={selectedDate}
                  month={month}
                  year={year}
                />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="p-4 flex gap-4 items-center justify-between bg-[#EDFDFF]">
          <div className="flex-1">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                // setScheduleSubmitClick(!scheduleSubmitClick);
                // setModalToExpand(["item-2"]);
                // setDateSelected("");
                setCheckedItems(checkedItemsBackup);
                // setModalToExpand(["item-2"]);
              }}
              disabled={isAbleToSubmit}
            >
              Revert
            </Button>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="tertiary"
              size="sm"
              onClick={submitManSchedule}
              disabled={isAbleToSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeesDropDown;

{
  /*
<SimpleBar
className="flex flex-col gap-3 mt-3 flex-1"
// style={{    //when the notification ALERT bar is visible use this styling
//   height: areAvatarsVisible
//     ? "calc(100vh - 647px)"
//     : "calc(100vh - 564px)",
// }}
style={{
  ///when the bar is hidden use this styling for employees dropdown
  height: areAvatarsVisible
    ? "calc(100vh - 588px)"
    : "calc(100vh - 485px)",
}}
>
<div className="px-[25px]">
  {filteredEmployees?.map(
    (listing: EmployeeCardData, index: number) => {
      if (listing?.isAligned === false) {
        return (
          <div key={index} className="flex items-center ">
            <Accordion itemSize="sm">
              <Accordion.Item
                value={`item-${index}`}
                className="mb-2"
              >
                <Accordion.Header>
                  <Accordion.Button className="w-full">
                    <span className="flex items-center gap-1 ">
                      <Checkbox
                        bgColor="bg-hit"
                        checked={checkedItems?.includes(
                          listing?._id
                        )}
                        className=" transform-none"
                        disabled={!selectedDate}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (selectedDate)
                            handleCheckboxChange(
                              listing?._id,
                              event
                            );
                        }}
                      />
                      {/* {DESIGNATION[listing?.designation]} */
}
{
  /*                   {listing?.employeeName}
                      <Chip
                        size="sm"
                        className={`text-moon-9 rounded-sm px-[8px] py-0 h-auto uppercase font-semibold ${getTextColor(
                          listing?.designation
                        )}`}
                      >
                        {listing?.designation}
                      </Chip>
                    </span>
                    <ControlsChevronDownSmall className="cst-accordian text-trunks text-moon-16 transition duration-200 moon-open:text-bulma" />
                  </Accordion.Button>
                </Accordion.Header>

                <Accordion.Content className="border-0 p-0">
                  <div className="p-3 flex w-full gap-2">
                    {/* <Checkbox
                      bgColor="bg-hit"
                      checked={checkedItems?.includes(
                        listing?._id
                      )}
                      disabled={!selectedDate}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (selectedDate)
                          handleCheckboxChange(
                            listing?._id,
                            event
                          );
                      }}
                    /> */
}
{
  /*}                 <div className="flex-1">
                      <EmployeeCard
                        employeeCardData={listing}
                        isChecked={checkedItems?.includes(
                          listing?._id
                        )}
                        selectedDate={selectedDate}
                        month={month}
                        year={year}
                      />
                    </div>
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      }
    }
  )}
</div>
  </SimpleBar>*/
}
