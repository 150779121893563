import React, { useState, useMemo } from "react";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  CellContext,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  GenericEdit,
  GenericDelete,
  GenericInfo,
  ControlsChevronRightSmall,
  ControlsChevronLeftSmall,
} from "@heathmont/moon-icons-tw";
import { Chip, IconButton } from "@heathmont/moon-core-tw";
import { Tooltip } from "react-tooltip";
import { IPatientJob } from "../../../types/operation-manager-interfaces/IPatientJob.interface";
import {
  IEmployeeTable,
  IPatientTable,
} from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import { IEmployeeJob } from "../../../types/operation-manager-interfaces/IEmployeeJob.interface";
import { fetchJobHistory } from "../../../services/operation-manager-services/job.service";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHistoryDetails } from "../../../features/history/history.slice";

interface TableProps {
  data: IEmployeeTable;
  onEdit: (data: IEmployeeJob) => void;
  onDelete: (id: IEmployeeJob) => void;
}

const EmployeeDetailTable: React.FC<TableProps> = ({
  data,
  onEdit,
  onDelete,
}) => {
  const rowsPerPage = 10;
  const columnHelper = createColumnHelper<IEmployeeJob>();
  const dispatch = useDispatch();
  const { id } = useParams();

  const getHistory = async (page: number = 1) => {
    const res: any = await fetchJobHistory(id || "", page);

    if (res.res) {
      dispatch(setHistoryDetails(res?.res));
    }
  };

  const CellRenderer = React.useCallback(
    (info: CellContext<IEmployeeJob, unknown>, fieldName: string) => {
      const error = info.row.original.validationErrors?.find(
        (error) => error.field === fieldName
      );

      const tooltipId = `tooltip-${info.row.id}-${fieldName}`;

      return (
        <div className="flex items-center space-x-2">
          {fieldName === "status" ? (
            <span
              className={`${getStatusColor(
                info.row.original.alreadyExists ? "Existing" : "New"
              )} px-3 py-1 rounded-md inline-flex`}
            >
              {info.row.original.alreadyExists ? "Existing" : "New"}
            </span>
          ) : fieldName === "assignedSection" ? (
            <span className={`text-gray-400`}>
              {info.row.original.sectionDetails?.sectionName || ""}
            </span>
          ) : (
            <span
              className={` ${
                fieldName === "employeeName" ? "text-black" : "text-gray-400"
              } ${
                fieldName === "designation" ||
                fieldName === "role" ||
                fieldName === "gender" ||
                fieldName === "seniorityLevel"
                  ? "capitalize"
                  : ""
              }`}
            >
              {info.getValue<string>()}
            </span>
          )}

          {error && (
            <>
              <a
                data-tooltip-id={tooltipId}
                data-tooltip-content={`Error in ${fieldName}: ${error.message}`}
              >
                <IconButton
                  variant="ghost"
                  size="sm"
                  icon={<GenericInfo />}
                  className="text-red-500"
                />
              </a>
              <Tooltip
                place="bottom"
                id={tooltipId}
                style={{ backgroundColor: "#fff", color: "red" }}
              />
            </>
          )}
        </div>
      );
    },
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("employeeId", {
        header: () => <span>ID</span>,
        cell: (info) => CellRenderer(info, "employeeId"),
      }),
      columnHelper.accessor("employeeName", {
        id: "employeeName",
        header: () => <span>Employee Name</span>,
        cell: (info) => CellRenderer(info, "employeeName"),
      }),
      columnHelper.accessor("role", {
        enableSorting: false,
        header: () => "Role",
        cell: (info) => CellRenderer(info, "role"),
      }),
      columnHelper.accessor("designation", {
        enableSorting: false,
        header: () => "Designation",
        cell: (info) => CellRenderer(info, "designation"),
      }),
      columnHelper.accessor("contactNo", {
        enableSorting: false,
        header: () => <span>Phone#</span>,
        cell: (info) => CellRenderer(info, "contactNo"),
      }),
      columnHelper.accessor("email", {
        enableSorting: false,
        header: "Email Address",
        cell: (info) => CellRenderer(info, "email"),
      }),
      columnHelper.accessor("gender", {
        enableSorting: false,
        header: "Gender",
        cell: (info) => CellRenderer(info, "gender"),
      }),
      columnHelper.accessor("joiningDate", {
        enableSorting: false,
        header: "Joining Date",
        cell: (info) => CellRenderer(info, "joiningDate"),
      }),
      columnHelper.accessor("assignedSection", {
        enableSorting: false,
        header: "Section",
        cell: (info) => CellRenderer(info, "assignedSection"),
      }),
      columnHelper.accessor("seniorityLevel", {
        enableSorting: false,
        header: "Seniority Level",
        cell: (info) => CellRenderer(info, "seniorityLevel"),
      }),
      columnHelper.accessor("alreadyExists", {
        enableSorting: false,
        header: "Status",
        cell: (info) => CellRenderer(info, "status"),
      }),
      columnHelper.accessor((row) => row, {
        enableSorting: false,
        header: "Action",
        cell: (info) => (
          <div className="flex space-x-2">
            <IconButton
              variant="ghost"
              icon={<GenericEdit />}
              size="sm"
              className="bg-blue-50 text-blue-500"
              onClick={() => onEdit(info.row.original)}
            />
            <IconButton
              variant="ghost"
              icon={<GenericDelete />}
              size="sm"
              className="bg-red-50 text-red-500"
              onClick={() => onDelete(info.row.original)}
            />
          </div>
        ),
      }),
    ],
    [CellRenderer, onEdit, onDelete]
  );

  const handleNext = () => {
    if (data.currentPage < data.totalPages) {
      getHistory(data.currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (data.currentPage > 1) {
      getHistory(data.currentPage - 1);
    }
  };

  const handlePageClick = (page: number) => {
    getHistory(page);
  };

  const table = useReactTable({
    data: data.items,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="overflow-auto bg-white max-h-[calc(100vh-375px)]">
        <table className="min-w-full">
          <thead className="bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 text-left bg-slate-200 capitalize font-semibold text-xs h-12"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-4 py-2 border-b border-slate-200 text-sm text-black"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-end p-4 px-6 gap-4">
        <div className="text-sm">
          Showing {rowsPerPage * (data.currentPage - 1) + 1} -{" "}
          {Math.min(rowsPerPage * data.currentPage, data.totalItems)} of{" "}
          {data.totalItems}
        </div>
        <div className="flex items-center space-x-2">
          <IconButton
            icon={<ControlsChevronLeftSmall />}
            variant="secondary"
            size="sm"
            onClick={handlePrevious}
            disabled={data.currentPage === 1}
          />
          {Array.from({ length: data.totalPages }).map((_, index) => (
            <button
              key={index}
              className={`w-8 h-8 rounded ${
                data.currentPage === index + 1
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <IconButton
            icon={<ControlsChevronRightSmall />}
            variant="secondary"
            size="sm"
            onClick={handleNext}
            disabled={data.currentPage === data.totalPages}
          />
        </div>
      </div>
    </>
  );
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "New":
      return "bg-green-100 text-green-600";
    case "Existing":
      return "bg-yellow-100 text-yellow-600";
    default:
      return "bg-gray-100 text-gray-600";
  }
};

export default EmployeeDetailTable;
