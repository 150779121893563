import React, { useEffect, useState, ChangeEvent } from "react";
import {
  Chip,
  Input,
  Label,
  Modal,
  Radio,
  Select,
  Dropdown,
  MenuItem,
  PrevButton,
} from "@heathmont/moon-core-tw";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@heathmont/moon-core-tw/lib/es/button/Button";
import {
  ROLE,
  SENIORITY_LEVEL,
} from "../../../enums/super-admin/filters.enums";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";

interface EmployeeData {
  employeeId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  joiningDate: Date | null;
  seniorityLevel: string;
  designation: string;
  role: string;
  hospital: string;
  branch: string;
  assignedSection: string;
  department: string | undefined;
  gender: string | undefined;
  address: string;
  isActive: boolean;
  temporaryRole: string;
  password?: string;
}

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  employeeData: EmployeeData;
  setEmployeeData: Function;
  sections: any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  edit: boolean;
  validationErrors?: any[];
  setValidationErrors?: (errors: any[]) => void;
}

type FieldType = keyof EmployeeData;

const EmployeeCreateEditModal: React.FC<IProps> = ({
  isOpen,
  closeModal,
  employeeData,
  setEmployeeData,
  handleSubmit,
  sections,
  edit,
  validationErrors,
  setValidationErrors,
}) => {
  const [passwordError, setPasswordError] = useState("");
  const contactNumberRegex = /^\+?[0-9]*$/;
  const [touched, setTouched] = useState<{
    [K in keyof EmployeeData]?: boolean;
  }>({});
  const [errors, setErrors] = useState<{ [K in keyof EmployeeData]?: string }>(
    {}
  );
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /.{8,32}$/;

  const handleChange = (
    field: FieldType,
    value: string | Date | null | boolean,
    type?: "dropdown" | "date" | "radio" | "section"
  ) => {
    setEmployeeData((prev: EmployeeData) => {
      if (field === "role") {
        const isAdminRole =
          value === ROLE.BRANCH_ADMIN || value === ROLE.NURSE_DIRECTOR;
        return {
          ...prev,
          [field]: value,
          department: isAdminRole ? "All" : "",
          assignedSection: isAdminRole ? "All" : "",
        };
      }

      if (type === "section") {
        const sectionData = value as any;
        return {
          ...prev,
          assignedSection: sectionData._id,
          department: sectionData.sectionName,
        };
      }

      return { ...prev, [field]: value };
    });
    setValidationErrors?.(
      validationErrors?.filter((err) => err.field !== field) || []
    );
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    handleSubmit();
  };

  const arrayToObject = (arr: Array<any>, message?: boolean) => {
    return arr.reduce(
      (obj, item) => ({
        ...obj,
        [item.field]: message ? true : item.message,
      }),
      {}
    );
  };

  useEffect(() => {
    if (validationErrors && validationErrors?.length > 0) {
      const errors = arrayToObject(validationErrors);
      setErrors(errors);
      setTouched(arrayToObject(validationErrors, true));
    } else {
      setErrors({});
    }
  }, [validationErrors, isOpen]);

  const handleBlur = (field: keyof EmployeeData) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const handleFocus = (field: keyof EmployeeData) => {
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  const validateField = (field: keyof EmployeeData) => {
    let error: any = null;
    if (!employeeData[field]) {
      error = "This field is required";
    }
    if (field === "email" && !emailRegex.test(employeeData.email)) {
      setErrors((prev) => ({ ...prev, [field]: "Required" }));
      return;
    }
    if (
      field === "contactNo" &&
      !contactNumberRegex.test(employeeData.contactNo)
    ) {
      error = "Invalid contact number format";
    }
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const validateForm = () => {
    const newErrors: { [K in keyof EmployeeData]?: string } = {};

    // Common validations for all scenarios
    if (!employeeData.employeeId)
      newErrors.employeeId = "Employee ID is required";
    if (!employeeData.firstName) newErrors.firstName = "First Name is required";
    if (!employeeData.lastName) newErrors.lastName = "Last Name is required";
    if (!employeeData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(employeeData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!employeeData.contactNo) {
      newErrors.contactNo = "Contact Number is required";
    } else if (!contactNumberRegex.test(employeeData.contactNo)) {
      newErrors.contactNo = "Invalid contact number format";
    }
    if (!employeeData.joiningDate)
      newErrors.joiningDate = "Joining Date is required";
    if (!employeeData.seniorityLevel)
      newErrors.seniorityLevel = "Seniority Level is required";
    if (!employeeData.designation)
      newErrors.designation = "Designation is required";
    if (!employeeData.role) newErrors.role = "Role is required";
    if (!employeeData.gender) newErrors.gender = "Gender is required";
    if (!employeeData.address) newErrors.address = "Address is required";

    // Conditional validations
    if (!edit) {
      if (
        employeeData.role !== ROLE.BRANCH_ADMIN &&
        employeeData.role !== ROLE.NURSE_DIRECTOR
      ) {
        if (!employeeData.assignedSection)
          newErrors.assignedSection = "Assigned Section is required";
        if (!employeeData.department)
          newErrors.department = "Department is required";
      }

      if (!employeeData.password) {
        newErrors.password = "Password is required";
      } else if (!passwordRegex.test(employeeData.password)) {
        newErrors.password = "Password must be 8-32 characters long";
      }
    } else {
      if (!employeeData.assignedSection)
        newErrors.assignedSection = "Assigned Section is required";
      if (!employeeData.department)
        newErrors.department = "Department is required";
    }

    const keys = Object.keys(newErrors);

    console.log(
      arrayToObject(
        keys.map((k) => {
          return { field: k };
        }),
        true
      )
    );

    setErrors(newErrors);
    setTouched(
      arrayToObject(
        keys.map((k) => {
          return { field: k };
        }),
        true
      )
    );
    return Object.keys(newErrors).length === 0;
  };

  console.log(touched, errors);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[50rem] bg-white rounded-xl overflow-x-hidden shadow-3xl max-h-[90dvh] overflow-y-auto">
        <div className="flex text-center flex-1 flex-col justify-center">
          <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus sticky top-0 bg-white z-10">
            <div className="flex-1 text-start">
              <h3 className="text-moon-24 font-semibold">
                {edit ? "Edit" : "Add"} Employee
              </h3>
              <p className="text-base text-[#8697A2]">
                Fill the following fields to {edit ? "update" : "add"} the
                employee details
              </p>
            </div>
            <Button className="bg-transparent" onClick={closeModal}>
              <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
            </Button>
          </div>

          <div className="mx-auto w-full py-6 px-4">
            <div className="flex flex-wrap -mx-1">
              <div className="w-full px-1 mb-3">
                <Label
                  htmlFor="employeeId"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Employee ID
                </Label>
                <Input
                  disabled={edit}
                  type="text"
                  id="employeeId"
                  value={employeeData.employeeId}
                  onChange={(e) => handleChange("employeeId", e.target.value)}
                  placeholder="Employee ID"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.employeeId && errors.employeeId
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  onBlur={() => handleBlur("employeeId")}
                  onFocus={() => handleFocus("employeeId")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="firstName"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  First Name
                </Label>
                <Input
                  type="text"
                  id="firstName"
                  value={employeeData.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  placeholder="First Name"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.firstName && errors.firstName
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  onBlur={() => handleBlur("firstName")}
                  onFocus={() => handleFocus("firstName")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="lastName"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Last Name
                </Label>
                <Input
                  type="text"
                  id="lastName"
                  value={employeeData.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  placeholder="Last Name"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.lastName && errors.lastName
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  onBlur={() => handleBlur("lastName")}
                  onFocus={() => handleFocus("lastName")}
                />
              </div>

              <div className="w-full px-1 mb-3">
                <Label
                  htmlFor="gender"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Gender
                </Label>
                <Radio
                  value={employeeData.gender}
                  className="flex gap-4"
                  onChange={(e) => handleChange("gender", e as string, "radio")}
                >
                  <Radio.Option value="male">
                    <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                    Male
                  </Radio.Option>
                  <Radio.Option value="female">
                    <Radio.Indicator className="moon-checked:border-primary after:bg-primary" />
                    Female
                  </Radio.Option>
                </Radio>
                {errors.gender && (
                  <p className="text-red-500 text-left text-xs">
                    {errors.gender}
                  </p>
                )}
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="email"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Email
                </Label>
                <Input
                  type="email"
                  id="email"
                  value={employeeData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  placeholder="Email"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.email && errors.email
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  onBlur={() => handleBlur("email")}
                  onFocus={() => handleFocus("email")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="contactNo"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Contact No
                </Label>
                <Input
                  type="text"
                  id="contactNo"
                  name="contactNo"
                  value={employeeData.contactNo}
                  onChange={(e) => handleChange("contactNo", e.target.value)}
                  placeholder="Contact No"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.contactNo && errors.contactNo
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  onBlur={() => handleBlur("contactNo")}
                  onFocus={() => handleFocus("contactNo")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3 relative z-[99]">
                <Label
                  htmlFor="joiningDate"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Joining Date
                </Label>
                <ReactDatePicker
                  className={` text-moon-16 text-bulma transition-shadow box-border relative z-[2] bg-gohan shadow-input hover:shadow-input-hov focus:shadow-input-focus focus:outline-none focus-visible::shadow-input-focus focus-visible::outline-none h-10 leading-10 rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:[&:not([readonly])]:hover:rounded-moon-i-sm rtl:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm rtl:invalid:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:hover:rounded-moon-i-sm ltr:[&:not([disabled])]:[&:not([readonly])]:focus:rounded-moon-i-sm ltr:invalid:rounded-moon-i-sm before:box-border after:box-border placeholder:text-trunks placeholder:opacity-100 placeholder:transition-opacity placeholder:delay-75 read-only:outline-0 read-only:border-none read-only:cursor-not-allowed read-only:hover:shadow-input read-only:focus:shadow-input read-only:focus-visible:shadow-input input-dt-shared invalid:shadow-input-err invalid:hover:shadow-input-err invalid:focus:shadow-input-err invalid:focus-visible:shadow-input-err mt-1 block w-full px-3 py-2 border border-gray-300 ${
                    touched.joiningDate && errors.joiningDate
                      ? "border-red-500 selected-error"
                      : "border-gray-300"
                  }`}
                  selected={employeeData.joiningDate}
                  onChange={(date) => handleChange("joiningDate", date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select date"
                  onBlur={() => handleBlur("joiningDate")}
                  onFocus={() => handleFocus("joiningDate")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="seniorityLevel"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Seniority Level
                </Label>
                <Dropdown
                  value={employeeData.seniorityLevel}
                  onChange={(value) =>
                    handleChange("seniorityLevel", value as string, "dropdown")
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select
                        open={open}
                        className={`capitalize mt-1 ${
                          touched.seniorityLevel && errors.seniorityLevel
                            ? "border-red-500 selected-error"
                            : ""
                        }`}
                      >
                        <span className="text-sm 2xl:text-base">
                          {employeeData.seniorityLevel === ""
                            ? "Seniority Level"
                            : employeeData.seniorityLevel}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        {Object.values(SENIORITY_LEVEL).map((level) => (
                          <Dropdown.Option key={level} value={level}>
                            {({ selected, active }) => (
                              <MenuItem
                                isActive={active}
                                isSelected={selected}
                                className="capitalize"
                              >
                                <span className="2xl:text-base text-sm">
                                  {level}
                                </span>
                              </MenuItem>
                            )}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3 relative z-[99]">
                <Label
                  htmlFor="designation"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Designation
                </Label>
                <Dropdown
                  value={employeeData.designation}
                  onChange={(value) =>
                    handleChange("designation", value as string, "dropdown")
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select
                        className={`capitalize mt-1 ${
                          touched.designation && errors.designation
                            ? "border-red-500 selected-error"
                            : ""
                        }`}
                        open={open}
                      >
                        <span className="capitalize 2xl:text-moon-16 text-moon-14">
                          {employeeData.designation === ""
                            ? "Select Designation"
                            : employeeData.designation}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        {Object.values(ROLE).map((level) => (
                          <Dropdown.Option key={level} value={level}>
                            {({ selected, active }) => (
                              <MenuItem
                                isActive={active}
                                isSelected={selected}
                                className="capitalize"
                              >
                                <span className="2xl:text-base text-sm">
                                  {level}
                                </span>
                              </MenuItem>
                            )}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>
              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="role"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Role
                </Label>
                <Dropdown
                  value={employeeData.role}
                  onChange={(value) =>
                    handleChange("role", value as string, "dropdown")
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select
                        className={`capitalize mt-1 ${
                          touched.role && errors.role
                            ? "border-red-500 selected-error"
                            : ""
                        }`}
                        open={open}
                      >
                        <span className="capitalize 2xl:text-moon-16 text-moon-14">
                          {employeeData.role === ""
                            ? "Select Role"
                            : employeeData.role}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        {Object.values(ROLE).map((level) => (
                          <Dropdown.Option key={level} value={level}>
                            {({ selected, active }) => (
                              <MenuItem
                                isActive={active}
                                isSelected={selected}
                                className="capitalize"
                              >
                                <span className="2xl:text-base text-sm">
                                  {level}
                                </span>
                              </MenuItem>
                            )}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="department"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Department
                </Label>
                <Dropdown
                  disabled={
                    employeeData.role === ROLE.BRANCH_ADMIN ||
                    employeeData.role === ROLE.NURSE_DIRECTOR
                  }
                  value={sections}
                  onChange={(value: any) =>
                    handleChange("department", value, "section")
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select open={open}>
                        <span className="text-sm 2xl:text-base">
                          {employeeData.role === ROLE.BRANCH_ADMIN ||
                          employeeData.role === ROLE.NURSE_DIRECTOR
                            ? "All Sections"
                            : employeeData.department === ""
                            ? "Select Section"
                            : employeeData.department}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        {sections?.map((section: any) => (
                          <Dropdown.Option key={section._id} value={section}>
                            {({ selected, active }) => (
                              <MenuItem isActive={active} isSelected={selected}>
                                <span className="2xl:text-base text-sm">
                                  {section.sectionName}
                                </span>
                              </MenuItem>
                            )}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="address"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Address
                </Label>
                <Input
                  type="text"
                  id="address"
                  value={employeeData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  placeholder="Address"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.address && errors.address
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  onBlur={() => handleBlur("address")}
                  onFocus={() => handleFocus("address")}
                />
              </div>

              {!edit && (
                <div className="w-1/2 px-1 mb-3">
                  <Label
                    htmlFor="password"
                    className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                  >
                    Password
                  </Label>
                  <Input
                    type="password"
                    id="password"
                    value={employeeData.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                    placeholder="Password"
                    className={`mt-1 block w-full px-3 py-2 border ${
                      touched.password && errors.password
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    onBlur={() => {
                      handleBlur("password");
                      if (
                        !passwordRegex.test(employeeData?.password as string) &&
                        employeeData.password
                      ) {
                        setPasswordError(
                          "Password should be at least 8 characters long."
                        );
                      }
                    }}
                    onFocus={() => {
                      handleFocus("password");
                      setPasswordError("");
                    }}
                  />
                  {passwordError && (
                    <p className="text-red-500 text-xs mt-1">{passwordError}</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer bg-white py-2 px-4 flex items-center justify-end gap-[8px]">
            <Button variant="secondary" onClick={closeModal}>
              Discard
            </Button>
            <Button
              variant="tertiary"
              onClick={(e: any) => handleFormSubmit(e)}
              className="text-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50"
            >
              {edit ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default EmployeeCreateEditModal;
