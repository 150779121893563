import React, { useEffect, useState } from "react";
import { ControlsChevronDownSmall } from "@heathmont/moon-icons-tw";
import { Button } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { getDashboardData } from "../../../services/nurse-manager-services/dashboard.service";
// import { Button } from "@heathmont/moon-core-tw/lib/search/private/components/ResultItem";
interface DashBoard {
  teamCapacity: number;
  totalPatients: number;
  criticalPatients: number;
  utilization: string;
}
interface Props {
  section: any;
}
const SchedleEmployeeStatus: React.FC<Props> = ({ section }) => {
  const [dashboardData, setDashboardData] = useState<DashBoard>();
  const { User } = useSelector((state: RootState) => state?.auth);

  const fetchDashboardData = async (sectionId: string) => {
    const res = await getDashboardData(sectionId);
    if (res?.res) {
      setDashboardData(res?.res as DashBoard);
    }
  };

  useEffect(() => {
    if (!User) return;
    const sectionId =
      User?.role === "nurse director" && section?._id
        ? section?._id
        : User?.assignedSection;
    fetchDashboardData(sectionId);
  }, [User, section]);
  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex items-start justify-between bg-primarydark  py-2.5 px-6 rounded-lg">
          <div className="flex items-center">
            <img className="me-4" src={"images/teamCapacity.svg"} alt="logo" />
            <div>
              <p className="text-white text-xs">Team Capacity</p>
              <p className="text-white text-xl">
                {dashboardData?.teamCapacity ?? 0}
                {/* <span className="text-white text-xs">
                  /{section.totalSchedules}
                </span> */}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-start justify-between bg-primarydark py-2.5 px-6 rounded-lg">
          <div className="flex items-center">
            <img
              className="me-4"
              // src={"images/domestic_worker.svg"}
              src={"images/Caridogram.svg"}
              alt="logo"
            />
            <div>
              <p className="text-white text-xs">Utilization</p>
              <p className="text-white text-xl">
                {dashboardData?.utilization}
                {/* <span className="text-white text-xs">
                  /{section.totalEmployees}
                </span> */}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between bg-primarydark py-2.5 px-6 rounded-lg">
          <div className="flex items-center">
            <img
              className="me-4"
              src={"images/numberOfPatients.svg"}
              alt="logo"
            />
            <div>
              <p className="text-white text-xs">Number of Patients</p>
              <p className="text-white text-xl">
                {dashboardData?.totalPatients}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between bg-primarydark py-2.5 px-6 rounded-lg">
          <div className="flex items-center">
            <img className="me-4" src={"images/outpatient.svg"} alt="logo" />
            <div>
              <p className="text-white text-xs">Critical Patients</p>
              <p className="text-white text-xl">
                {dashboardData?.criticalPatients}{" "}
                {/* <span className="text-white text-xs">
                  /{section.totalEmployees}
                </span> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SchedleEmployeeStatus;
