import React, { useEffect, useState, useCallback } from "react";
import { Dropdown } from "@heathmont/moon-core-tw";
import { NotificationsBell } from "@heathmont/moon-icons-tw";
import { Button } from "@heathmont/moon-core-tw";
import NotificationList from "./NotificationList";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import { RootState, store } from "../../store/store";
import {
  setSnackBar,
  setToastMessage,
} from "../../features/ToastSlice/toastSlice";
import {
  getNotifications,
  getUnreadNotifications,
} from "../../services/nurse-manager-services/notifications.service";
import {
  setNotifications,
  setRespondedScheduleId,
} from "../../features/Notification Slice/notificationSlice";
import { notificationSocket } from "../../utils/notifications.socket.service";
import { apiBaseUrl } from "../../constants/environmentConstants";

export interface NotificationsList {
  notifications: Notification[];
}

export interface Notification {
  _id: string;
  message: string;
}

interface NotificationRead {
  hasUnreadNotification: boolean;
}

interface Props {
  isNeedRoute?: string;
}

const Notification: React.FC<Props> = ({ isNeedRoute }) => {
  const [option, setOption] = useState({ name: "Choose a name..." });
  const { User } = useSelector((state: RootState) => state.auth);
  const [notificationsList, setNotificationsList] = useState<NotificationsList>(
    { notifications: [] }
  );
  const [isUnreadNotification, setIsUnreadNotification] =
    useState<NotificationRead>({ hasUnreadNotification: false });
  const [isConnected, setIsConnected] = useState(false);

  const fetchNotifications = useCallback(async () => {
    try {
      const res = await getNotifications();
      if (res?.res) {
        setNotificationsList(res.res as NotificationsList);
        await fetchUnreadNotification();
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
      store.dispatch(setSnackBar("error"));
      store.dispatch(setToastMessage("Failed to fetch notifications"));
    }
  }, []);

  const fetchUnreadNotification = useCallback(async () => {
    try {
      const res = await getUnreadNotifications();
      if (res?.res) {
        setIsUnreadNotification(res.res as NotificationRead);
      }
    } catch (error) {
      console.error("Error fetching unread notifications:", error);
    }
  }, []);

  const handleSocketEvent = useCallback(
    (eventName: string, callback: (data: any) => void) => {
      if (notificationSocket.socket) {
        notificationSocket.socket.off(eventName); // Remove previous listeners
        notificationSocket.socket.on(eventName, (data: any) => {
          try {
            callback(data);
            fetchUnreadNotification();
            store.dispatch(setNotifications(true));
          } catch (error) {
            console.error(`Error handling ${eventName}:`, error);
          }
        });
      }
    },
    [fetchUnreadNotification]
  );

  const setupSocketEvents = useCallback(() => {
    if (!notificationSocket.socket) return;

    notificationSocket.socket.on("connect", () => {
      console.log("Socket connected");
      setIsConnected(true);
    });

    notificationSocket.socket.on("disconnect", (reason) => {
      console.log("Socket disconnected:", reason);
      setIsConnected(false);
      if (reason === "io server disconnect") {
        notificationSocket.socket?.connect();
      }
    });

    notificationSocket.socket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
      setIsConnected(false);
    });

    if (User?.role === "nurse manager") {
      handleSocketEvent("respondScheduleRequest", (data) => {
        store.dispatch(setSnackBar("notification"));
        store.dispatch(setToastMessage(data?.message));
        store.dispatch(setRespondedScheduleId(data?.schedule?._id));
      });
    } else if (User?.role === "nurse director") {
      handleSocketEvent("scheduleSubmitted", (data) => {
        store.dispatch(setSnackBar("notification"));
        store.dispatch(setToastMessage(data?.message));
      });
    }

    return () => {
      if (notificationSocket.socket) {
        notificationSocket.socket.removeAllListeners();
      }
    };
  }, [User?.role, handleSocketEvent]);

  useEffect(() => {
    if (!User?.id || !apiBaseUrl) return;

    const initializeSocket = async () => {
      try {
        notificationSocket.socket?.disconnect();
        await notificationSocket.initializeConnection(
          apiBaseUrl as string,
          User.id
        );
        setupSocketEvents();
        fetchUnreadNotification();
      } catch (error) {
        console.error("Error initializing socket:", error);
        store.dispatch(setSnackBar("error"));
        store.dispatch(
          setToastMessage("Failed to connect to notification service")
        );
      }
    };

    initializeSocket();

    return () => {
      notificationSocket.socket?.removeAllListeners();
      notificationSocket.socket?.disconnect();
    };
  }, [User?.id, apiBaseUrl, setupSocketEvents, fetchUnreadNotification]);

  return (
    <Dropdown value={option} onChange={setOption} className="p-0">
      <Dropdown.Trigger>
        <Button
          className={`inline-flex items-center justify-center w-[30px] h-[30px] rounded-full text-moon-24 relative ${
            isNeedRoute
              ? "bg-white bg-opacity-25 text-[#272D36] "
              : "text-white bg-primarydarkl"
          } p-0 `}
          onClick={fetchNotifications}
        >
          <NotificationsBell />
          {!isConnected && (
            <span className="absolute bottom-[-3px] left-[-3px] bg-yellow-500 w-2 h-2 rounded-full"></span>
          )}
        </Button>
        {isUnreadNotification?.hasUnreadNotification && (
          <span className="absolute top-[-3px] right-[-2px] bg-dodoria w-3 h-3 rounded-full"></span>
        )}
      </Dropdown.Trigger>
      <Dropdown.Options className="min-w-[440px] max-w-[440px] p-0">
        <div className="flex justify-between w-full py-[12px] px-[16px]">
          <div className="inline-flex items-center">
            <h4 className="mr-[13px]">Notifications</h4>
          </div>
        </div>
        <SimpleBar className="max-h-[540px]">
          <NotificationList
            notifications={notificationsList?.notifications as Notification[]}
          />
        </SimpleBar>
      </Dropdown.Options>
    </Dropdown>
  );
};

export default Notification;
