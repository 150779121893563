import React, { useEffect, useState } from "react";
import SectionMenu from "../../../UI/SectionMenu";
import SchedleEmployeeStatus from "../../../ScheduleStatusEmploye";
import NeedEmployee from "../../../NeedEmployee";
import { getSections } from "../../../../../services/branch-admin-services/section.service";
import { setSelectedSection } from "../../../../../features/nurse-manager/Time Slice/timeSlice";
import { RootState, store } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { setSection } from "../../../../../features/nurse-manager/Section Slice/sectionSlice";
import { useLocation } from "react-router-dom";
interface Section {
  _id: string;
  sectionName: string;
}

interface Props {
  section?: any;
  onChangeSection?: (data: Section) => void;
  controlView?: boolean;
  sectionName?: string;
}
export default function ScheduleStatus({
  section,
  onChangeSection,
  controlView,
  sectionName,
}: Props) {
  const [selectedSection, setSelectedSection] = useState<Section>(
    {} as Section
  );

  const [sections, setSections] = useState<Section[]>([]);
  const location = useLocation();
  const getSectionsData = async () => {
    const response: any = await getSections();
    if (!response.err) {
      setSections(response?.sections);
      if (location.pathname == "/scheduler") {
        setSections((prevSections: any) => {
          return [{ sectionName: "All Sections", _id: "" }, ...prevSections];
        });
      }

      // setSelectedSection({ sectionName: "All Sections", _id: "" });
      // store.dispatch(setSection({ sectionName: "All Sections", _id: "" }));
    }
  };

  const User = useSelector((state: RootState) => state.auth.User?.role);
  useEffect(() => {
    if (User === "nurse director") {
      getSectionsData();
    }
  }, [User]);

  // const a = onChangeSection && onChangeSection("A");
  // console.log("A", a);
  return (
    <>
      <div className="py-2 sm:px-7 px-4 bg-primary">
        <div className="flex flex-wrap items-center xl:gap-0 gap-4">
          <div className="xl:w-1/4 w-full">
            <div className="flex items-center text-white text-sm">
              Section name
            </div>
            {!controlView ? (
              <SectionMenu
                sections={section as any}
                options={sections as Section[]}
                onChangeSection={onChangeSection as any}
              />
            ) : (
              <h3 className="text-3xll  text-white mb-[5px] mr-[15px]">
                {sectionName}
              </h3>
            )}{" "}
            {/* <span className="text-sm text-black bg-cyan-300 rounded-md px-2 py-1 inline-block">
              1 nurse can take care of 4 patients
            </span> */}
          </div>
          <div className="xl:w-2/4 w-full">
            <SchedleEmployeeStatus section={section as any} />
          </div>
          {/* <div className="w-1/4 ps-3">
            <NeedEmployee />
          </div> */}
        </div>
      </div>
    </>
  );
}
