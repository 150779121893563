import { MenuItem } from "@heathmont/moon-core-tw";
import React, { useState, useCallback, useEffect } from "react";
import {
  ControlsChevronDown,
  ArrowsSorting,
  FilesMagazine,
} from "@heathmont/moon-icons-tw";

interface SubOption {
  id: string;
  label: string;
  isSelected: boolean;
}

interface Option {
  id: number;
  label: string;
  isSelected: boolean;
  name: string;
  subMenu?: SubOption[];
}

interface MenuItem {
  id: number;
  label: string;
  isOpen: boolean;
  subItems: Option[];
}

interface FilterSideBarProps {
  onDataUpdate: (updatedData: MenuItem[]) => void;
  sidebarData: any;
}

const FilterSideBar: React.FC<FilterSideBarProps> = ({
  onDataUpdate,
  sidebarData,
}) => {
  const AVAILABLITY_STATUS: any = {
    Available: (
      <span className={"w-3 h-3 rounded-full inline-flex bg-roshi"}></span>
    ),
    "Not Available": (
      <span className={"w-3 h-3 rounded-full inline-flex bg-dodoria"}></span>
    ),
    "NURSE MANAGER": (
      <span className={"w-3 h-3 rounded-full inline-flex bg-whis"}></span>
    ),
    NURSE: <span className={"w-3 h-3 rounded-full inline-flex bg-whis"}></span>,
    "SENIOR NURSE": (
      <span className={"w-3 h-3 rounded-full inline-flex bg-dodoria"}></span>
    ),
    HCA: (
      <span className={"w-3 h-3 rounded-full inline-flex bg-krillin"}></span>
    ),
  };
  const [menuItems, setMenuItems] = useState<MenuItem[]>(sidebarData);

  const toggleSelection = (menuItems: any, selectedId: any) => {
    return menuItems.map((menuItem: any) => {
      const updatedSubItems = menuItem.subItems.map((subItem: any) => {
        return {
          ...subItem,
          isSelected: subItem.id === selectedId,
        };
      });
      return {
        ...menuItem,
        subItems: updatedSubItems,
      };
    });
  };

  //right one
  const handleOptionClick = (menuId: any, subItemId: any) => {
    setMenuItems((prevData) =>
      prevData.map((menuItem) => {
        if (menuItem.id === menuId) {
          const updatedSubItems = menuItem.subItems.map((subItem) => {
            if (subItem.id === subItemId) {
              return { ...subItem, isSelected: !subItem.isSelected };
            } else if (
              (menuItem.label === "Role" ||
                menuItem.label === "Request Type") &&
              subItem.id !== subItemId
            ) {
              // For "Leave type", allow multiple selections
              return subItem;
            } else {
              // For other menu items, use radio button
              return { ...subItem, isSelected: subItem.id === subItemId };
            }
          });
          const updatedMenuItem = { ...menuItem, subItems: updatedSubItems };
          return updatedMenuItem;
        }
        return menuItem;
      })
    );
  };
  useEffect(() => {
    onDataUpdate(menuItems);
  }, [menuItems, onDataUpdate]);
  // setSelectedItems(menuItems);

  return (
    <>
      <div className="flex">
        <span className="bg-cyan-50 text-cyan-600 w-6 h-6 top-2 left-2 rounded-full flex justify-center items-center">
          <ArrowsSorting className="text-moon-18" />
        </span>{" "}
        &nbsp;
        <span className="font-bold text-lg">Sort</span>
      </div>

      {menuItems.map((menuItem) => (
        <div key={menuItem.id}>
          <MenuItem onClick={() => {}}>
            {/* <span className="bg-cyan-50 text-cyan-600 w-6 h-6 top-2 left-2 rounded-full flex justify-center items-center">
              {menuItem.label === "Sort" ? (
                // <ArrowsSorting className="text-moon-18" />
              ) : (
                // <FilesMagazine className="text-moon-18" />
              )}
            </span> */}
            <MenuItem.Title>
              <p className="leading-6 text-moon-18 font-semibold text-gray-600">
                {menuItem.label}
              </p>
            </MenuItem.Title>
            {/* <ControlsChevronDown
              className={`w-6 ${menuItem.isOpen && "rotate-180"}`}
            /> */}
          </MenuItem>

          {menuItem.isOpen && (
            <div className="pl-5">
              {menuItem.subItems.map((subItem) => (
                <div key={subItem.id}>
                  <MenuItem
                    role="checkbox"
                    isSelected={subItem.isSelected}
                    // onClick={() => console.log("sub", menuItem.id, subItem)}
                    onClick={() => handleOptionClick(menuItem?.id, subItem?.id)}
                    className="focus:bg-none"
                  >
                    <MenuItem.Checkbox className="moon-checked:bg-cyan-600" />
                    <MenuItem.Title>
                      {AVAILABLITY_STATUS[subItem?.name]} {subItem?.name}
                    </MenuItem.Title>
                  </MenuItem>

                  {subItem.subMenu && (
                    <div className="pl-5">
                      {subItem.subMenu.map((subOption) => (
                        <MenuItem
                          key={subOption.id}
                          role="radio"
                          isSelected={subOption.isSelected}
                          // onClick={() =>
                          //   // handleOptionClick(
                          //   //   menuItem.id,
                          //   //   subItem.id,
                          //   //   subOption.id,
                          //   // )

                          // }
                          className="focus:bg-none"
                        >
                          <MenuItem.Checkbox className="moon-checked:bg-cyan-600" />
                          {/* <MenuItem.Title className='2xl:text-moon-16 text-moon-14 '> */}
                          <MenuItem.Title>{subOption?.label}</MenuItem.Title>
                        </MenuItem>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default FilterSideBar;

// const handleOptionClick = (menuId:any, subItemId:any) => {
//   setSidebarData((prevData) => {
//     // Check if it's "Sort" or "Availability"
//     if (menuId === 1 || menuId === 2) {
//       return toggleSelection(prevData, subItemId);
//     } else {
//       // If it's "Designation," toggle checkbox
//       return prevData.map((menuItem) => {
//         if (menuItem.id === menuId) {
//           const updatedSubItems = menuItem.subItems.map((subItem) => {
//             if (subItem.id === subItemId) {
//               return { ...subItem, isSelected: !subItem.isSelected };
//             }
//             return subItem;
//           });
//           return {
//             ...menuItem,
//             subItems: updatedSubItems,
//           };
//         } else {
//           // For other menus, unselect all "Designation" checkboxes
//           const updatedSubItems = menuItem.subItems.map((subItem) => ({
//             ...subItem,
//             isSelected: false,
//           }));
//           return {
//             ...menuItem,
//             subItems: updatedSubItems,
//           };
//         }
//       });
//     }
//   });
// };

// const handleOptionClick = useCallback(
//   (menuId: string, optionId: string, subOptionId?: string) => {
//     setMenuItems((prevMenuItems) =>
//       prevMenuItems.map((menuItem) => {
//         if (menuItem.id === menuId) {
//           const updatedSubItems = menuItem.subItems.map((subItem) => {
//             if (subItem.id === optionId && subItem.label=== optionId.label) {
//               if (subItem.subMenu) {
//                 const updatedSubMenu = subItem.subMenu.map((subOption) => {
//                   if (subOption.id === subOptionId) {
//                     return {
//                       ...subOption,
//                       isSelected: !subOption.isSelected,
//                     };
//                   }
//                   return subOption;
//                 });

//                 const isAllSubOptionsSelected = updatedSubMenu.every(
//                   (subOption) => subOption.isSelected,
//                 );

//                 const updatedSubItem = {
//                   ...subItem,
//                   isSelected: isAllSubOptionsSelected,
//                   subMenu: updatedSubMenu,
//                 };

//                 return updatedSubItem;
//               } else {
//                 return { ...subItem, isSelected: !subItem.isSelected };
//               }
//             }
//             return subItem;
//           });

//           const isAllSubItemsSelected = updatedSubItems.every(
//             (subItem) => subItem.isSelected,
//           );

//           const updatedMenuItem = {
//             ...menuItem,
//             isSelected: isAllSubItemsSelected,
//             subItems: updatedSubItems,
//           };

//           return updatedMenuItem;
//         }
//         return menuItem;
//       }),
//     );
//   },
//   [setMenuItems],
// );
// const handleOptionClick = (menuId: any, subItemId: any) => {
//   setMenuItems((prevData) =>
//     prevData.map((menuItem) => {
//       if (menuItem.id === menuId) {
//         const updatedSubItems = menuItem.subItems.map((subItem) => {
//           if (menuItem.label === "Leave type" && subItem.id === subItemId) {
//             // Toggle the checkbox behavior for "Leave type"
//             return { ...subItem, isSelected: !subItem.isSelected };
//           } else if (
//             menuItem.label === "Leave type" &&
//             subItem.id !== subItemId
//           ) {
//             // For "Leave type", allow multiple selections
//             return subItem;
//           } else {
//             // For other menu items, use radio button behavior
//             return { ...subItem, isSelected: subItem.id === subItemId };
//           }
//         });
//         return { ...menuItem, subItems: updatedSubItems };
//       }
//       return menuItem;
//     }),
//   );
// };

// const selectedItem: any = sidebarData?.flatMap((menuItem: any) =>
//   menuItem?.subItems?.filter((subItem: any) => subItem?.isSelected),
// );
// console.log("selected", selectedItem);
// // setSelectedItems([selectedItem]);
// setStateT(selectedItem);
// const selectedItem: any = sidebarData?.flatMap((menuItem: any) =>
//   menuItem?.subItems?.filter((subItem: any) => subItem?.isSelected),
// );
// console.log("selected", selectedItem);
// setSelectedItems(selectedItem);

// const selectedLabels = sidebarData.flatMap((menuItem: any) =>
//   menuItem.subItems
//     .filter((subItem: any) => subItem.isSelected)
//     .map((subItem: any) => subItem.label),
// );
// const selectedItem: any = sidebarData?.flatMap((menuItem: any) =>
//   menuItem?.subItems?.filter((subItem: any) => subItem?.isSelected),
// );
// console.log("selected", selectedItem);

// useEffect(() => {
// if (selectedItem) {
//   setSelectedItems(selectedItem as any);
// }
// }, [selectedItem]);
// selectedItems(selectedItem);
