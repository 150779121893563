import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/IUser.interface";
import {
  IJobHistory,
  Job,
} from "../../types/operation-manager-interfaces/IJobHistroy.interface";
import { RootState } from "../../store/store";
interface InitialState {
  history: IJobHistory[];
  historyDetails: IJobHistory | null;
  jobs: Job[];
}
const initialState: InitialState = {
  history: [],
  historyDetails: null,
  jobs: [],
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setHistory: (state, action: PayloadAction<IJobHistory[]>) => {
      state.history = action.payload;
    },
    setJobs: (state, action: PayloadAction<Job[]>) => {
      state.jobs = action.payload;
    },
    setHistoryDetails: (state, action: PayloadAction<IJobHistory>) => {
      state.historyDetails = action.payload;
    },
  },
});

export const { setHistory, setHistoryDetails, setJobs } = historySlice.actions;
export const historySelector = (state: RootState) => state.history.history;
export const jobsSelector = (state: RootState) => state.history.jobs;
export const historyDetailsSelector = (state: RootState) =>
  state.history.historyDetails;
export default historySlice.reducer;
