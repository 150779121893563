import { useEffect, useState } from "react";
import TitlePage from "../components/TitlePage";
import PatientTabs from "../components/Patients";
import { fetchJobHistory } from "../../../services/operation-manager-services/job.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  historyDetailsSelector,
  setHistoryDetails,
} from "../../../features/history/history.slice";
import { getSections } from "../../../services/branch-admin-services/section.service";

const PatientFile = () => {
  const [sections, setSections] = useState([]);
  const history = useSelector(historyDetailsSelector);
  const dispatch = useDispatch();
  const { id } = useParams();

  const getJobHistory = async (
    page: number = 1,
    search: string = "",
    tab?: number
  ) => {
    const res: any = await fetchJobHistory(id || "", page, search, tab);

    if (res.res) {
      dispatch(setHistoryDetails(res?.res));
    }
  };

  const getSectionsData = async () => {
    const response: any = await getSections();
    if (!response.err) {
      setSections(response?.sections);
    }
  };

  useEffect(() => {
    getJobHistory();
    getSectionsData();
  }, []);
  return (
    <>
      <TitlePage
        title={history?.fileName || ""}
        content="See all the data with reference to the hospital"
        backButton={true}
      />
      {history && (
        <PatientTabs
          getHistory={getJobHistory}
          sections={sections}
          data={history}
        />
      )}
    </>
  );
};

export default PatientFile;
