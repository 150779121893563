import { Avatar } from "@heathmont/moon-core-tw";
import React from "react";
import moment from "moment";
import { IUserData } from "../../../../../types/IUserData.interface";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";
const CardProfile = () => {
  const profileData: IUserData = JSON.parse(sessionStorage.getItem("user")!);
  // console.log("Profile", JSON.parse(profileData!));
  return (
    <div className="flex flex-col w-full">
      <div className="w-full flex items-center gap-[15px] mb-[30px]">
        <span className="inline-flex rounded-full shadow-md ">
          <Avatar
            size="xl"
            className="rounded-full border-2 overflow-hidden"
            imageUrl={profileData?.imgURL ?? "images/Avatar.png"}
          ></Avatar>
        </span>
        <div className="flex-1">
          <h3 className="text-moon-20 font-semibold text-blackcst  leading-[28px]">
            {capitalizeWords(
              profileData?.firstName + " " + profileData?.lastName
            )}
          </h3>
          <p className="text-moon-14 font-semibold text-primary leading-[20px]">
            {capitalizeWords(profileData?.role)}
          </p>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-4 gap-3 my-4">
          <div className="">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Email:
            </h4>
          </div>
          <div className="col-span-3">
            <h4 className="2xl:text-moon-14 text-moon-12">
              {profileData?.email}
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 my-4">
          <div className="">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Phone:
            </h4>
          </div>
          <div className="col-span-3">
            <h4 className="2xl:text-moon-14 text-moon-12">
              {profileData?.contactNo}
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 my-4">
          <div className="">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Identity:
            </h4>
          </div>
          <div className="col-span-3">
            <h4 className="2xl:text-moon-14 text-moon-12">
              {profileData?.employeeId}
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 my-4">
          <div className="">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Joined:
            </h4>
          </div>
          <div className="col-span-3">
            <h4 className="t2xl:text-moon-14 text-moon-12">
              {moment(profileData?.joiningDate)?.format("DD MMM YYYY")}
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3 my-4">
          <div className="">
            <h4 className="text-gray-400 text-moon-10 font-semibold uppercase tracking-[0.5px] leading-[24px]">
              Address:
            </h4>
          </div>
          <div className="col-span-3">
            <h4 className="2xl:text-moon-14 text-moon-12">
              {/* Suzy Queue. 4455 Landing Lange, APT 4. Louisville, KY 40018-1234. */}
              {profileData?.address}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardProfile;
