import React, { useState } from "react";
import { Button, IconButton } from "@heathmont/moon-core-tw";
import {
  ControlsChevronRight,
  ControlsEye,
  GenericAlarmRound,
  GenericDelete,
} from "@heathmont/moon-icons-tw";
import ScheduleRecordCard from "../../../../../Components/nurse-manager/UI/ScheduleRecordCard";
import { scheduleMonthYear } from "../../add-schedule";
import { RootState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  IScheduleHistory,
  ScheduleRecordCardData,
} from "../../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import ConfirmationModal from "../../../../super-admin/settings/hospital/widgets/ConfirmationModal";
import {
  ControlsChevronLeftSmall,
  ControlsChevronRightSmall,
} from "@heathmont/moon-icons";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import GenericTable from "../../../../operation-manager/components/Table";
import { setDate } from "../../../../../features/nurse-manager/Time Slice/timeSlice";
interface Props {
  scheduleRecordsData: IScheduleHistory;
  setSelectedRecord: (data: ScheduleRecordCardData) => void;
  setIsShowSchedule: (check: boolean) => void;
  setSelectedScheduleId: (scheduleId: string) => void;
  deleteSchedules: Function;
  pageNumber: number;
  getRecords: (page: number) => void;
}
const ViewRecord: React.FC<Props> = ({
  deleteSchedules,
  scheduleRecordsData,
  setSelectedRecord,
  setIsShowSchedule,
  setSelectedScheduleId,
  pageNumber,
  getRecords,
}) => {
  const totalItems = scheduleRecordsData?.pagination?.totalItems || 0;
  const limit = scheduleRecordsData?.pagination?.limit || 12;
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper<ScheduleRecordCardData>();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [confirmationData, setConfirmationData] = useState<any>(null);

  const openConfirmationModal = (data: any) => {
    setConfirmationData(data);
    setIsOpenConfirm(true);
  };

  const columns = [
    columnHelper.accessor("month", {
      header: () => <span>Schedule</span>,
      cell: (info) => (
        <span className="text-black ">
          {moment()
            .month(info.row.original?.month - 1)
            .format("MMMM ") +
            moment().year(info.row.original?.year).format("YYYY")}
        </span>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <span>Schedule</span>,
      cell: (info) => {
        const status = info.getValue();
        return (
          <span
            className={`px-2 py-2 text-sm inline-flex leading-none rounded capitalize ${
              status === "Accepted"
                ? "bg-green-100 text-green-700"
                : status === "Draft"
                ? "bg-yellow-100 text-yellow-700"
                : status === "Rejected"
                ? "bg-red-100 text-red-700"
                : "bg-neutral-200 text-gray-700"
            }`}
          >
            {status}
          </span>
        );
      },
    }),
    columnHelper.accessor("scheduleVersion", {
      header: () => <span>Version</span>,
      cell: (info) => <span className="text-black font-semibold">{info.getValue()}</span>,
    }),
    columnHelper.accessor("createdAt", {
      header: () => <span>Created Date</span>,
      cell: (info) => (
        <span className="text-black ">
          {moment(new Date(info.getValue())).format("DD MMM YYYY")}
        </span>
      ),
    }),
    columnHelper.accessor("updatedAt", {
      header: () => <span>Updated Date</span>,
      cell: (info) => (
        <span className="text-black ">
          {moment(new Date(info.getValue())).format("DD MMM YYYY")}
        </span>
      ),
    }),
    columnHelper.accessor("_id", {
      header: () => <span>Action</span>,
      cell: (info) => (
        <div className="flex items-center gap-4">
          <IconButton
            icon={<ControlsEye />}
            variant="tertiary"
            onClick={() => handleOpenSchedule(info.row.original)}
          />
          {info.row.original.status !== "accepted" && (
            <IconButton
              variant="ghost"
              icon={<GenericDelete className="text-moon-24" />}
              onClick={(e: any) => {
                e.stopPropagation();
                openConfirmationModal({
                  title: "Confirm Delete Schedule",
                  actionFor: "Branch",
                  message: (
                    <p className="text-moon-18 mb-[28px]">
                      {" "}
                      The Schedule{" "}
                      <strong className="font-semibold ">
                        {moment()
                          .month(info.row.original?.month - 1)
                          .format("MMMM ") +
                          moment().year(info.row.original?.year).format("YYYY")}
                      </strong>
                      <br />
                      will be deleted
                    </p>
                  ),
                  confirmAction: "Delete",
                  id: {
                    section: info.row.original._id,
                  },
                });
              }}
              className="bg-red-100 text-red-700"
            />
              
          )}
        </div>
      ),
    }),
  ];

  const handleOpenSchedule = (schedule: ScheduleRecordCardData) => {
    setSelectedRecord(schedule);
    setIsShowSchedule(true);
    dispatch(
      setDate({
        month: moment()
          .month(schedule?.month - 1)
          .format("MMMM"),
        currentMonth: schedule?.month,
        year: schedule?.year,
      })
    );
    setSelectedScheduleId(schedule?._id);
  };

  const handleConfirmAction = () => {
    deleteSchedules(confirmationData.id.section);
  };

  return (
    <>
      <div className="flex justify-between items-center lg:px-[30px] px-4 py-5 bg-gray-50 spacy-y-3">
        <div>
          <h3 className="text-moon-24 text-bulma ">View Records</h3>
          {/* <p className="flex items-center text-trunks">
            <GenericAlarmRound className="me-1 text-moon-24 text-bulma ps-0" />
            Clicking on
            <span className="flex items-center ms-1">
              <GenericDelete className="me-1 text-moon-20 text-trunks" /> will
              delete all the selected schedule permanently.
            </span>
          </p> */}
        </div>
        {/* <div className="flex items-center">
          <p className="me-4 text-moon-16 text-trunks">
            Return to current schedule
          </p>
          <Button
            variant="tertiary"
            className="text-hit text-moon-14 bg-[rgb(36,156,186,0.15)]"
          >
            {date.month + " " + date.year}
            <ControlsChevronRight className="text-moon-24 " />
          </Button>
        </div> */}
      </div>
      <div className=" bg-gray-50">
        {scheduleRecordsData?.schedules?.length > 0 ? (
          <>
            <div className=" min-h-[calc(100vh-368px)]  ">
              <GenericTable
                data={scheduleRecordsData?.schedules}
                columns={columns}
                getData={getRecords}
                {...scheduleRecordsData.pagination}
              />
            </div>
          </>
        ) : (
          <div className="sectionsvg flex items-center w-full justify-center text-center">
            <img
              src="images/no-schedule-to-approve.svg"
              alt="No Records available"
              className="inline-block"
            />
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpenConfirm={isOpenConfirm}
        closeConfirmModal={() => setIsOpenConfirm(false)}
        confirmationData={confirmationData}
        onConfirm={handleConfirmAction}
      />
    </>
  );
};

export default ViewRecord;
