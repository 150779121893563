import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@heathmont/moon-core-tw";
import ProfileButton from "./widget/profile-header/ProfileButton";

import { ControlsPlus, GenericSearch } from "@heathmont/moon-icons";
import Notification from "./widget/notification/Notification";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
export default function ScheduleHeader({
  isLoginRoute,
}: {
  isLoginRoute?: boolean;
}) {
  const data = useSelector((state: RootState) => state.auth.User);
  const user = {
    value: data?.firstName + " " + data?.lastName,
    role: data?.role,
    imageUrl: "images/Avatar.png",
    roles: [data?.role],
  };

  const location = useLocation().pathname;

  return (
    <>
      <header
        className={`py-4 sm:px-7 px-4 bg-gradient-to-tll    ${
          isLoginRoute ? "hidden" : "block"
        }`}
      >
        <nav className="flex justify-between items-center">
          <div>
            <Link to="/" className="cursor-pointer">
              <img src={"images/weblogo.svg"} alt="logo" />
            </Link>
          </div>
          <ul
            className={`flex items-center 2xl:space-x-9 space-x-5 text-xs cursor-pointer text-whitelight `}
          >
            {/* Commented below as it is not required for branch admin */}
            <li
              className={`hover:text-white ${
                location === "/" ? "text-white underline" : ""
              }`}
            >
              <Link to="/">Hospital</Link>
            </li>
            <li className={`flex items-center text-white`}>
              <ControlsPlus className="text-moon-24 bg-greencustom text-primary me-2 rounded-full " />{" "}
              Contact Support
            </li>
          </ul>
          <ul className="flex items-center space-x-2 text-xs">
            <li className="flex">
              <ProfileButton
                value={user.value}
                role={user.role}
                imageUrl={user.imageUrl}
                roles={user.roles}
              />
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
