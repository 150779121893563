import React, { useEffect, useState } from "react";
import {
  Chip,
  Input,
  Label,
  Modal,
  Radio,
  Select,
  Dropdown,
  MenuItem,
  PrevButton,
} from "@heathmont/moon-core-tw";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@heathmont/moon-core-tw/lib/es/button/Button";
import { ControlsCloseSmall } from "@heathmont/moon-icons-tw";

interface ProfileData {
  patientId: string;
  status: string | undefined;
  critical: boolean;
  patientName: string;
  admissionDate: Date | null;
  dischargeDate: Date | null;
  sectionId: string;
  allocatedSection: string;
  assignedRoom: string;
  referredDoctor: string;
  needyProfile: string | undefined;
  _id?: string;
}

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  profileData: ProfileData;
  setProfileData: Function;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  sections: any[];
  edit: boolean;
  validationErrors?: any[];
  setValidationErrors?: (errors: any[]) => void;
}

const PatientEditCreateModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  profileData,
  setProfileData,
  handleSubmit,
  sections,
  edit,
  validationErrors,
  setValidationErrors,
}) => {
  const [touched, setTouched] = useState<{
    [K in keyof ProfileData]?: boolean;
  }>({});
  const [errors, setErrors] = useState<{ [K in keyof ProfileData]?: string }>(
    {}
  );

  const handleBlur = (field: keyof ProfileData) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    validateField(field);
  };

  const handleFocus = (field: keyof ProfileData) => {
    setErrors((prev) => ({ ...prev, [field]: undefined }));
  };

  const validateField = (field: keyof ProfileData) => {
    let error: any = null;
    if (!profileData[field]) {
      error = "This field is required";
    }
    setErrors((prev) => ({ ...prev, [field]: error }));
  };

  const handleChange = (
    field: keyof ProfileData,
    value: string | boolean | Date | null,
    additionalField?: { field: keyof ProfileData; value: string }
  ) => {
    setProfileData((prevProfileData: ProfileData) => {
      const updatedData = {
        ...prevProfileData,
        [field]: value,
      };

      if (additionalField) {
        (updatedData[additionalField.field] as any) = additionalField.value;
      }

      return updatedData;
    });
    setValidationErrors?.(
      validationErrors?.filter((err) => err.field !== field) || []
    );
  };

  const isFormValid = () => {
    return (
      profileData.patientId !== "" &&
      profileData.status !== "" &&
      profileData.patientName !== "" &&
      profileData.assignedRoom !== "" &&
      profileData.referredDoctor !== "" &&
      profileData.needyProfile !== "" &&
      profileData.dischargeDate !== null &&
      profileData.sectionId !== "" &&
      profileData.admissionDate !== null
    );
  };

  useEffect(() => {
    if (validationErrors && validationErrors?.length > 0) {
      const errors = arrayToObject(validationErrors);
      setErrors(errors);
      setTouched(arrayToObject(validationErrors, true));
    } else {
      setErrors({});
    }
  }, [validationErrors, isOpen]);

  const arrayToObject = (arr: Array<any>, message?: boolean) => {
    return arr.reduce(
      (obj, item) => ({
        ...obj,
        [item.field]: message ? true : item.message,
      }),
      {}
    );
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Backdrop />
      <Modal.Panel className="max-w-[50rem] bg-white rounded-xl overflow-x-hidden shadow-3xl max-h-[90dvh] overflow-y-auto">
        <div className="flex text-center flex-1 flex-col justify-center">
          <div className="modal-header flex items-center justify-between gap-[8px] px-4 py-2 border-b border-beerus sticky top-0 bg-white z-10">
            <div className="flex-1 text-start">
              <h3 className="text-moon-24 font-semibold">
                {edit ? "Update Patient" : "Add Patient"}
              </h3>
              <p className="text-base text-[#8697A2]">
                Fill the following fields to{" "}
                {edit ? "update patient" : "add patient"}
              </p>
            </div>
            <Button className="bg-transparent" onClick={closeModal}>
              <ControlsCloseSmall className="w-6 h-6 text-[#000000]" />
            </Button>
          </div>

          <div className="mx-auto w-full py-6 px-4">
            <div className="flex flex-wrap -mx-1">
              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="patientId"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Patient ID
                </Label>
                <Input
                  type="text"
                  id="patientId"
                  name="patientId"
                  value={profileData.patientId}
                  onChange={(e) => handleChange("patientId", e.target.value)}
                  placeholder="Patient ID"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.patientId && errors.patientId
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  onBlur={() => handleBlur("patientId")}
                  onFocus={() => handleFocus("patientId")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="status"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Status
                </Label>
                <Dropdown
                  value={profileData.status}
                  onChange={(value) => handleChange("status", value as string)}
                  onBlur={() => handleBlur("status")}
                  onFocus={() => handleFocus("status")}
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select open={open} className="mt-1">
                        <span className="2xl:text-base text-sm">
                          {profileData.status}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        <Dropdown.Option value="Active">
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              <span className="2xl:text-base text-sm">
                                Active
                              </span>
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                        <Dropdown.Option value="Inactive">
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              Inactive
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="critical"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Critical
                </Label>
                <Dropdown
                  value={profileData.critical}
                  onChange={(value) =>
                    handleChange("critical", value as boolean)
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select open={open} className="mt-1">
                        {profileData.critical ? "True" : "False"}
                      </Dropdown.Select>
                      <Dropdown.Options>
                        <Dropdown.Option value={true}>
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              True
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                        <Dropdown.Option value={false}>
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              False
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="patientName"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Patient Name
                </Label>
                <Input
                  type="text"
                  id="patientName"
                  name="patientName"
                  value={profileData.patientName}
                  onChange={(e) => handleChange("patientName", e.target.value)}
                  placeholder="Patient Name"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.patientName && errors.patientName
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  onBlur={() => handleBlur("patientName")}
                  onFocus={() => handleFocus("patientName")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="admissionDate"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Admission Date
                </Label>
                <div>
                  <ReactDatePicker
                    className={`mt-1 block w-full z-50 relative px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                      touched.admissionDate && errors.admissionDate
                        ? "border-red-500"
                        : ""
                    }`}
                    selected={profileData.admissionDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date: Date | null) =>
                      handleChange("admissionDate", date)
                    }
                    onInputError={console.log}
                    placeholderText="Select Date"
                  />
                </div>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="dischargeDate"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Discharge Date
                </Label>
                <div className="">
                  <ReactDatePicker
                    className={`mt-1 block w-full z-50 relative px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                      touched.dischargeDate && errors.dischargeDate
                        ? "border-red-500"
                        : ""
                    }`}
                    dateFormat="dd/MM/yyyy"
                    selected={profileData.dischargeDate}
                    onChange={(date: Date | null) =>
                      handleChange("dischargeDate", date)
                    }
                    placeholderText="Select Date"
                  />
                </div>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="allocatedSection"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  <span className="2xl:text-base text-sm">
                    Allocated Section
                  </span>
                </Label>
                <Dropdown
                  value={sections}
                  onChange={(value: any) =>
                    handleChange("sectionId", value._id, {
                      field: "allocatedSection",
                      value: value.sectionName,
                    })
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select
                        open={open}
                        className={`mt-1 ${
                          touched.allocatedSection && errors.allocatedSection
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <span className="2xl:text-base text-sm">
                          {profileData.allocatedSection
                            ? profileData.allocatedSection
                            : "Select Section"}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        {sections?.map((section: any) => (
                          <Dropdown.Option key={section._id} value={section}>
                            {({ selected, active }) => (
                              <MenuItem isActive={active} isSelected={selected}>
                                <span className="2xl:text-base text-sm">
                                  {section.sectionName}
                                </span>
                              </MenuItem>
                            )}
                          </Dropdown.Option>
                        ))}
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="assignedRoom"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Assigned Room
                </Label>
                <Input
                  type="text"
                  id="assignedRoom"
                  name="assignedRoom"
                  value={profileData.assignedRoom}
                  onChange={(e) => handleChange("assignedRoom", e.target.value)}
                  placeholder="Assigned Room"
                  className={`mt-1 relative block w-full px-3 py-2 border ${
                    touched.assignedRoom && errors.assignedRoom
                      ? "border-red-500"
                      : "border-gray-300"
                  } z-0 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  onBlur={() => handleBlur("assignedRoom")}
                  onFocus={() => handleFocus("assignedRoom")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="referredDoctor"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Referred Doctor
                </Label>
                <Input
                  type="text"
                  id="referredDoctor"
                  name="referredDoctor"
                  value={profileData.referredDoctor}
                  onChange={(e) =>
                    handleChange("referredDoctor", e.target.value)
                  }
                  placeholder="Referred Doctor"
                  className={`mt-1 block w-full px-3 py-2 border ${
                    touched.referredDoctor && errors.referredDoctor
                      ? "border-red-500"
                      : "border-gray-300"
                  } z-0 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  onBlur={() => handleBlur("referredDoctor")}
                  onFocus={() => handleFocus("referredDoctor")}
                />
              </div>

              <div className="w-1/2 px-1 mb-3">
                <Label
                  htmlFor="needyProfile"
                  className="text-start 2xl:text-moon-16 text-moon-14 2xl:pb-2 pb-0"
                >
                  Needy Profile
                </Label>
                <Dropdown
                  value={profileData.needyProfile}
                  onChange={(value) =>
                    handleChange("needyProfile", value as string)
                  }
                >
                  {({ open }) => (
                    <>
                      <Dropdown.Select
                        open={open}
                        className={`mt-1 ${
                          touched.needyProfile && errors.needyProfile
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <span className="2xl:text-base text-sm">
                          {profileData.needyProfile}
                        </span>
                      </Dropdown.Select>
                      <Dropdown.Options>
                        <Dropdown.Option value="High">
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              <span className="2xl:text-base text-sm">
                                High
                              </span>
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                        <Dropdown.Option value="Medium">
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              <span className="2xl:text-base text-sm">
                                Medium
                              </span>
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                        <Dropdown.Option value="Low">
                          {({ selected, active }) => (
                            <MenuItem isActive={active} isSelected={selected}>
                              <span className="2xl:text-base text-sm">Low</span>
                            </MenuItem>
                          )}
                        </Dropdown.Option>
                      </Dropdown.Options>
                    </>
                  )}
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="modal-footer bg-white py-2 px-4 flex items-center justify-end gap-[8px]">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
            >
              Discard
            </Button>
            <Button
              variant="tertiary"
              onClick={(e: any) => handleSubmit(e)}
              disabled={isFormValid() ? false : true}
              className="text-white rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50"
            >
              {edit ? "Update" : "Create"}
            </Button>
          </div>
        </div>
      </Modal.Panel>
    </Modal>
  );
};

export default PatientEditCreateModal;
