import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import {
  IEmployeeData,
  IShift,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";
import { getScheduleByDay } from "../../../../services/nurse-manager-services/nursemanager.service";
import {
  IEmployee,
  IEmployeeRecord,
} from "../../../../types/nurse-manager-interfaces/IWeekDrawerData.interface";
import ScheduleDrawer from "../../Drawers/view-schedule/ScheduleDrawer";
import { setEmployees } from "../../../../features/nurse-manager/Week Filter/weekSlice";

interface Props {
  month: IEmployeeData[];
  monthDetail: IMonthDropDown;
  year: IYearDropDown;
  scheduleId: string;
}
const MonthDate: React.FC<Props> = ({
  month,
  monthDetail,
  year,
  scheduleId,
}) => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.weekFilter
  );
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const User = useSelector((state: RootState) => state.auth.User?.role);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<IEmployeeRecord>();
  const [allEmployees, setAllEmployees] = useState<IEmployee[]>();
  const [hca, setHca] = useState<IEmployee[]>();
  const [nurse, setNurse] = useState<IEmployee[]>();
  const [seniorNurse, setSeniorNurse] = useState<IEmployee[]>();
  const [dateCopy, setDateCopy] = useState<Moment>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFilter === "Nurse") {
      fetchScheduleByDayForNurse();
    } else if (selectedFilter === "HCA") {
      fetchScheduleByDayForHCA();
    } else if (selectedFilter === "Senior Nurse") {
      fetchScheduleByDayForSeniorNurse();
    } else {
      allEmployeesFilter();
    }
  }, [selectedFilter]);

  // const selectedDay: number = Number(date?.day); //current day
  // const selectedMonth: string = date?.month;
  // const selectedYear: number = Number(date?.year);

  const selectedDay: number = moment().date(); //current day
  const selectedMonthIndex: number = moment().month(monthDetail?.name).month();
  const firstDay = moment([year?.year, selectedMonthIndex])
    .startOf("month")
    .format("D");
  const daysInMonth: any = moment([year?.year, selectedMonthIndex])
    .endOf("month")
    .format("D");
  const renderCalendarDates = () => {
    return Array.from({ length: daysInMonth }, (_, index) => {
      const day: any = parseInt(firstDay) + index;
      const isCurrentDay =
        selectedDay === day &&
        monthDetail?.monthName === moment().format("MMMM");
      const dateClassName = isCurrentDay ? "bg-primary text-white" : "";

      return (
        <div
          key={day}
          className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center ${dateClassName}`}
        >
          <div className="text-moon-14 font-semibold transition-colors">
            {/* {day} */}
          </div>
        </div>
      );
    });
  };
  // const renderCalendarDates = () => {
  //   for (let i: any = firstDay; calendarDates.length < daysInMonth; i++) {
  //     const date1 = calendarDates.length + 1;
  //     const isCurrentDay =
  //       selectedDay === date1 && selectedMonth === moment().format("MMMM");

  //     const dateClassName = isCurrentDay ? "bg-blackcst  text-white" : "";

  //     calendarDates.push(
  //       <div
  //         key={date1}
  //         className={`flex-1 h-[64px] border border-sky2 border-s-0 px-3 py-2 inline-flex justify-center items-center ${dateClassName}`}
  //       >
  //         <div className="text-moon-14 font-semibold transition-colors">
  //           {date1}
  //         </div>
  //       </div>,
  //     );
  //   }

  //   return calendarDates;
  // };

  // Extract date (day)
  const dateFormat = (timestamp: any) => {
    const date: any = new Date(timestamp);
    const day: any = date.getDate();

    return day;
  };

  const fetchScheduleByDay = async (params: Moment) => {
    setDateCopy(params);
    const data = {
      day: params.format("DD"),
      month: params.format("MM"),
      year: params.format("YYYY"),
      scheduleId: scheduleId,
      dayShift: selectedShift === "sun" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
    };
    const res: any = await getScheduleByDay(data);
    setData(res?.res);
    dispatch(setEmployees(res?.res?.specificDayRecord.employees));
    setAllEmployees(res?.res?.specificDayRecord.employees);
    // setFilterEmployees(res?.res?.specificDayRecord.employees);
    setNurse([] as IEmployee[]);
    setHca([] as IEmployee[]);
    setSeniorNurse([] as IEmployee[]);
  };

  const fetchScheduleByDayForSeniorNurse = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      Senior_Nurse: true,
    };
    if (seniorNurse?.length === 0) {
      const res: any = await getScheduleByDay(date);
      // setFilterEmployees(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
      setSeniorNurse(res?.res?.specificDayRecord.employees);
    } else {
      dispatch(setEmployees(seniorNurse));
      // setFilterEmployees(seniorNurse);
    }
  };

  const fetchScheduleByDayForHCA = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      HCA: true,
    };

    if (hca?.length === 0) {
      const res: any = await getScheduleByDay(date);
      setHca(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
    } else {
      dispatch(setEmployees(hca));
    }
    // const res = await getScheduleByDay()
  };

  const fetchScheduleByDayForNurse = async () => {
    const date: any = {
      day: dateCopy?.format("DD"),
      month: dateCopy?.format("MM"),
      year: dateCopy?.format("YYYY"),
      scheduleId: scheduleId,
      dayShift:
        selectedShift === "sun" ? true : selectedShift === "all" ? true : false,
      nightShift: selectedShift === "moon" ? true : false,
      Nurse: true,
    };
    if (nurse?.length === 0) {
      const res: any = await getScheduleByDay(date);
      console.log("RES", res);
      setNurse(res?.res?.specificDayRecord.employees);
      dispatch(setEmployees(res?.res?.specificDayRecord.employees));
      // setFilterEmployees(res?.res?.specificDayRecord.employees);
    } else {
      // setFilterEmployees(nurse);
      dispatch(setEmployees(nurse));
    }
  };
  const allEmployeesFilter = () => {
    // const { Employees } = useSelector((state: RootState) => state.weekFilter);
    dispatch(setEmployees(allEmployees));
  };

  const openDrawer = (date: Moment) => {
    if (selectedShift === "all") return;
    if (User === "nurse manager") {
      setIsOpen(true);
      fetchScheduleByDay(date);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={`flex w-full`}>
        {month ? (
          month[0]?.shifts?.map((monthData: IShift, index: number) => (
            <div
              onClick={() => {
                openDrawer(moment(new Date(monthData.date)));
              }}
              key={index}
              className={`flex-1 h-[64px] border border-sky2 border-s-0 px-2 py-2 inline-flex justify-center ${
                selectedShift === "all"
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              } items-center 
              ${
                moment().date() === dateFormat(monthData?.date) &&
                monthDetail?.monthName === moment().format("MMMM")
                  ? "bg-primary text-white"
                  : ""
              }
              `}
            >
              <div>
                <span
                  className={`w-2 h-2 rounded-full inline-flex ${
                    // selectedShift === "sun" &&
                    selectedShift === "sun" && monthData?.isDayOverUtilization
                      ? "bg-krillin"
                      : selectedShift === "moon" &&
                        monthData?.isNightOverUtilization
                      ? "bg-krillin"
                      : selectedShift === "sun"
                      ? monthData?.isDayBalanced
                        ? "bg-roshi"
                        : "bg-dodoria"
                      : selectedShift === "moon"
                      ? monthData?.isNightBalanced
                        ? "bg-roshi"
                        : "bg-dodoria"
                      : selectedShift === "all" &&
                        (monthData?.isDayOverUtilization ||
                          monthData?.isNightOverUtilization)
                      ? "bg-krillin"
                      : selectedShift === "all" &&
                        !monthData?.isDayBalanced &&
                        !monthData?.isNightBalanced
                      ? "bg-dodoria"
                      : monthData?.isDayBalanced && monthData?.isNightBalanced
                      ? "bg-roshi"
                      : "bg-krillin"
                  }`}
                ></span>

                <div className="flex flex-wrap w-full">
                  {" "}
                  {dateFormat(monthData?.date)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
          // <div className="flex flex-wrap w-full"> {renderCalendarDates()}</div>
        )}
      </div>
      <ScheduleDrawer
        data={data as IEmployeeRecord}
        isOpen={isOpen}
        setIsOpen={setIsOpen as any}
        handleClose={handleClose}
      />
    </>
  );
};
export default MonthDate;
