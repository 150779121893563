import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import FooterCalendar from "./FooterCalendar/FooterCalendar";
import MonthDays from "../TopbarCalendar/MonthDays";
import MonthDate from "../TopbarCalendar/MonthDate";
import TopbarCalendar from "../TopbarCalendar/TopbarCalendar";
import EmployeeShiftMonthly from "./EmployeeShiftMonthly";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../store/store";
import { getEmployeesMonthlySchedule } from "../../../../services/nurse-manager-services/nursemanager.service";
import {
  IEmployeeData,
  IEmployeeDataResponse,
} from "../../../../types/nurse-manager-interfaces/IEmployeeMonthlyScheduleResponse.interface";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";
import { ISectionInfo } from "../../../../types/nurse-manager-interfaces/ISectionInfo.interface";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../features/ToastSlice/toastSlice";
import { ScheduleRecordCardData } from "../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import moment from "moment";
interface Props {
  sectionInfo: ISectionInfo;
  isScheduleAvailable: boolean;
  setIsScheduleAvailable: (scheduleCheck: boolean) => void;
  fetchAgain?: boolean;
  selectedSection?: any;
  month?: IMonthDropDown;
  year?: IYearDropDown;
  selectedScheduleId?: string;
  setSelectedScheduleId?: (id: string) => void;
  selectedRecord: ScheduleRecordCardData;
}

const MonthlySchedule: React.FC<Props> = ({
  sectionInfo,
  fetchAgain,
  isScheduleAvailable,
  setIsScheduleAvailable,
  selectedSection,
  month,
  year,
  selectedScheduleId,
  setSelectedScheduleId,
  selectedRecord,
}) => {
  const { User } = useSelector((state: RootState) => state.auth);
  const section = useSelector((state: RootState) => state.section);
  const [employeeData, setEmployeeData] = useState<IEmployeeDataResponse>(
    {} as IEmployeeDataResponse
  );
  const employeesMonthlySchedule = async () => {
    const currentDate = moment();
    const data: any = await getEmployeesMonthlySchedule(
      sectionInfo ? sectionInfo?.month : ((currentDate.month() + 1) as number),
      sectionInfo ? sectionInfo?.year : (currentDate.year() as number),
      User?.assignedSection !== "All"
        ? (User?.assignedSection as string)
        : sectionInfo !== undefined
        ? sectionInfo?.section
        : selectedSection?._id,
      sectionInfo?._id ?? selectedRecord?._id
    );
    if (data?.res) {
      data?.res?.response?.length === 0
        ? setIsScheduleAvailable(false)
        : setIsScheduleAvailable(true);
      setEmployeeData(data?.res);
      return;
    }
    // store.dispatch(setSnackBar("error"));
    // store.dispatch(setToastMessage(data?.res?.message));
  };
  useEffect(() => {
    if (
      (!User?.assignedSection || !month?.monthName || !year?.name) &&
      User?.role !== "nurse director"
    ) {
      return;
    }
    if (User?.role === "nurse director") {
      employeesMonthlySchedule();
    } else if (
      User?.role === "nurse manager" ||
      User?.temporaryRole === "nurse manager"
    ) {
      setSelectedScheduleId && setSelectedScheduleId("");
      employeesMonthlySchedule();
    }
  }, [User, month, year, selectedSection]);

  useEffect(() => {
    if (sectionInfo || fetchAgain) {
      employeesMonthlySchedule();
    }
  }, [sectionInfo, fetchAgain]);

  return (
    <>
      {employeeData?.employeeData ? (
        <>
          <div className="flex flex-wrap">
            <div className="w-1/6">
              <TopbarCalendar
                month={month?.monthName as string}
                year={year?.year as string}
              />
            </div>
            <div className="w-5/6">
              <MonthDate
                month={employeeData?.employeeData as IEmployeeData[]}
                monthDetail={month as IMonthDropDown}
                year={year as IYearDropDown}
                scheduleId={selectedScheduleId || ""}
              />
            </div>
          </div>
          <div className="flex bg-beerus">
            <div className="w-1/6">
              <div className="h-[64px] 2xl:px-[30px] px-3 pe-[14px] py-2  border-r border-sky2  flex items-center justify-between">
                <h3 className="2xl:text-moon-20 text-moon-16 font-medium transition-colors">
                  Employee
                </h3>
                <h3 className="2xl:text-moon-16 text-moon-12  font-medium text-gray-500">
                  Total Shifts
                </h3>
              </div>
            </div>
            <div className="w-5/6">
              <MonthDays
                month={month?.label as number}
                year={year?.year as string}
              />
            </div>
          </div>

          {/* <SimpleBar className="h-[calc(100vh-553px)]  overflow-y-auto">  for no notification line*/}
          <SimpleBar className="table_height">
            <EmployeeShiftMonthly
              data={employeeData?.employeeData as IEmployeeData[]}
              month={month?.label as number}
              year={year?.year as string}
            />
          </SimpleBar>
          <FooterCalendar />
        </>
      ) : (
        <div className="forapprovel flex items-center text-center justify-center flex-col">
          <img
            src={"images/no-schedule-for-month.svg"}
            alt="NO SCHEDULE AVAILABLE"
          />
          <p className="font-bold mt-5">
            {month?.name + " " + year?.year}{" "}
            <span className="font-semibold text-trunks">
              schedule not found
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default MonthlySchedule;
