import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@heathmont/moon-core-tw";
import ProfileButton from "./widget/profile-header/ProfileButton";

import { ControlsPlus, GenericSearch } from "@heathmont/moon-icons";
import Notification from "../../../notification/Notification";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getHospitalData } from "../../../../services/super-admin-services/hospital.service";
export default function ScheduleHeader({
  isLoginRoute,
}: {
  isLoginRoute?: boolean;
}) {
  const { User } = useSelector((state: RootState) => state.auth);
  const [hospital, setHospital] = useState<any>();
  const location = useLocation().pathname;
  const user = {
    value: User?.firstName + " " + User?.lastName,
    role: User?.role,
    imageUrl: User?.imgURL ?? "/images/Avatar.png",
    roles: [User?.role],
  };

  const fetchHospitalData = async () => {
    try {
      const response: any = await getHospitalData(User?.hospital as string);
      if (response) {
        setHospital(response.res.hospitalDB);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (User?.hospital) fetchHospitalData();
  }, [User]);

  return (
    <>
      <header
        className={`py-4 sm:px-7 px-4 bg-primary relative   ${
          isLoginRoute ? "hidden" : "block"
        }`}
      >
        <nav className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-4">
            <Link to="/" className="cursor-pointer">
              <img src={"images/weblogo.svg"} alt="logo" />
            </Link>
            {/* <div className="flex items-center rounded-xl bg-primarydark px-2 py-1"> */}
            <div className="lg:flex hidden items-center rounded-xl  px-2 py-1">
              <img
                className="me-2 w-[37px] h-[37px] rounded-full"
                src={hospital ? hospital.logo : ""}
                alt=""
              />
              <div>
                <p className="text-white text-moon-14">
                  {hospital ? hospital.name : "Hospital name"}
                </p>
                <p className=" text-white text-moon-9">{User?.branchName}</p>
              </div>
            </div>
          </div>
          <ul
            className={`lg:flex hidden items-center 2xl:space-x-9 space-x-5 text-xs cursor-pointer text-whitelight `}
          >
            <li
              className={`hover:text-white ${
                location === "/home" ? "text-white underline" : ""
              }`}
            >
              <Link to="/home">View</Link>
            </li>
            <li
              className={`hover:text-white ${
                location === "/preferences" ? "text-white underline" : ""
              }`}
            >
              <Link to="/preferences">Preferences</Link>
            </li>
            {/* Commented below as it is not required for branch admin */}
            {/* <li className='hover:text-white'>
              <Link to='/settings'>Settings</Link>
            </li> */}
            <li className={`flex items-center text-white`}>
              <ControlsPlus className="text-moon-24 bg-greencustom text-primary me-2 rounded-full " />{" "}
              Contact Support
            </li>
          </ul>
          <ul className="flex items-center space-x-2 text-xs">
            {/* <li className="flex">
              <Button
                className={`inline-flex items-center justify-center w-[30px] h-[30px] rounded-full text-moon-24  text-white bg-primarydarkl p-0 `}
              >
                <GenericSearch />
              </Button>
            </li> */}
            <li className="flex">
              <Notification />
            </li>
            <li className="flex">
              <ProfileButton
                value={user.value}
                role={user.role}
                imageUrl={user.imageUrl}
                roles={user.roles}
              />
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
