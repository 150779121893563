import jwtDecode from "jwt-decode";
import { removeUser, setAuthUser } from "../features/Auth Slice/authSlice";
import { store } from "../store/store";
import { create } from "apisauce";
import spinnerSvc from "../utils/spinner-service";
import { INewPasswordRequest } from "../types/INewPasswordRequest.interface";
import { ITokens } from "../types/ITokens.interface";
import { IEmail } from "../types/IEmail.interface";
import { IFormValues } from "../types/IFormValues.interface";
import { ITokenVerfication } from "../types/ITokenVerfication.interface";
import { notificationSocket } from "../utils/notifications.socket.service";
import io, { Socket } from "socket.io-client";
import { apiBaseUrl } from "../constants/environmentConstants";

export const authApi = create({
  baseURL: apiBaseUrl,
  headers: { Accept: "application/json" },
});

authApi.axiosInstance.interceptors.request.use(
  async (config) => {
    spinnerSvc.start();
    return config;
  },
  (err) => console.error(err)
);
authApi.axiosInstance.interceptors.response.use(
  (response) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
  }
);
export const authLogin = async (loginRequest: IFormValues) => {
  try {
    const response: any = await authApi.post("/employee/signIn", loginRequest);
    if (response?.data) {
      const tokenBase64 = response?.data?.accessToken;
      const tokenData: any = await jwtDecode(tokenBase64);
      sessionStorage.setItem("user", JSON.stringify(tokenData));
      store.dispatch(setAuthUser(tokenData));
      storeTokens(response?.data);
    }
    console.log("Response of Login Page", response?.data);
    return response?.data;
  } catch (error) {
    console.log("Error", error);
    return { err: error };
  }
};

export const refreshToken = async () => {
  const rtToken = sessionStorage.getItem("rtToken");
  try {
    console.log("call new token", rtToken);
    const { data, ok }: any = await authApi.get(
      "employee/getNewTokens",
      undefined,
      {
        headers: { Authorization: `Bearer ${rtToken}` },
      }
    );
    console.log("refresh Token", data);
    if (ok) {
      storeTokens(data);
      return data?.accessToken;
    }

    return null;
  } catch (error) {
    return null;
  }
};
//user authApi in forget password instead of "api" service
export const resetPassword = async (email: string) => {
  try {
    const response = await authApi.post("/admin/forgetPassword", {
      email: email,
    });
    if (response && response.ok) {
      return { mailSent: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

export const verifyResetPassword = async (params: ITokenVerfication) => {
  try {
    const response = await authApi.post(`/admin/verifyResetToken`, params);
    console.log("response of verify", response);
    if (response && response?.ok) {
      return { verified: true };
    } else {
      throw new Error("No such user found");
    }
  } catch (error) {
    return { err: error };
  }
};

export const changePassword = async (params: INewPasswordRequest) => {
  try {
    const response = await authApi.put(`/admin/changePassword`, params);
    console.log("response of verify", response);
    if (response && response?.ok) {
      return { changedSuccess: true };
    } else {
      throw new Error("There is an error in password change");
    }
  } catch (error) {
    return { err: error };
  }
};

export const logout = () => {
  store.dispatch(removeUser());
  notificationSocket?.disconnectSocket();
};

export const storeTokens = (tokens: ITokens) => {
  const { refreshToken, accessToken } = tokens;
  sessionStorage.setItem("acToken", accessToken);
  sessionStorage.setItem("rtToken", refreshToken);
};

// import createApi from "../utils/axios";
// import { METHODS } from "../enums/axios.enums";
// import { ILoginRequest } from "../types/interfaces/ILoginRequest.interface";
// import { ILoginResponse } from "../types/interfaces/ILoginRespose.interface";
// import { IRefreshTokenResponse } from "../types/interfaces/IRefreshTokenResponse.interface";
// import jwtDecode from "jwt-decode";
// import { setAuthUser } from "../features/authSlice";
// import { IUserData } from "../types/interfaces/IUserData.interface";
// import { useDispatch } from "react-redux";
// import { store } from "../store/store";
// const authApi = createApi("employee");

// export const authLogin = async (
//   loginRequest: ILoginRequest,
// ): Promise<ILoginResponse> => {
//   const { data } = await authApi({
//     url: "/signIn",
//     method: METHODS.POST,
//     data: loginRequest,
//   });
//   if (data) {
//     const tokenBase64 = data?.accessToken;
//     const tokenData = jwtDecode(tokenBase64);
//     sessionStorage.setItem("user", JSON.stringify(tokenData));
//     store.dispatch(setAuthUser(tokenData));
//     storeTokens(data);
//   }
//       return data;

// };

// export const refreshToken = async (): Promise<IRefreshTokenResponse> => {
//   const refreshToken = sessionStorage.getItem("rtToken");

//   const { data } = await authApi({
//     url: "/getNewTokens",
//     method: METHODS.GET,
//     headers: { Authorization: `Bearer ${refreshToken}` },
//   });

//   storeTokens(data);
//   console.log("refreshtokendata", data);
//   return data?.accessToken;
// };

// export const logout = () => {
//   sessionStorage.removeItem("acToken");
//   sessionStorage.removeItem("rtToken");
// };

// const storeTokens = (tokens: any) => {
//   const { refreshToken, accessToken } = tokens;
//   sessionStorage.setItem("acToken", accessToken);
//   sessionStorage.setItem("rtToken", refreshToken);
// };
