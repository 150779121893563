import { Avatar, Chip } from "@heathmont/moon-core-tw";
import React, { useEffect, useState } from "react";
import { GenericAlarmRound, OtherMoon } from "@heathmont/moon-icons-tw";
import { EmployeeCardData } from "../../../../../types/nurse-manager-interfaces/IManualSchedulingRes.interface";
import moment from "moment";
import { Icon } from "@iconify/react";
import { getBorderColor, getTextColor } from "../../../../Colors/colorSetting";
import DrawerEmployee from "../../../Drawers/employee-detail/DrawerEmployee";
import { IEmployeeDetails } from "../../../../../types/nurse-manager-interfaces/IEmployeeProfileSchedule.interface";
import { getEmployeeProfileData } from "../../../../../services/nurse-manager-services/employee.service";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/IDateDropDown.interface";
interface Props {
  employeeCardData: EmployeeCardData;
  selectedDate?: any;
  isChecked?: boolean;
  isInAutoAllocate?: boolean;
  month?: IMonthDropDown;
  year?: IYearDropDown;
}
// linkText,
const EmployeeCard: React.FC<Props> = ({
  employeeCardData,
  selectedDate,
  isChecked,
  isInAutoAllocate,
  month,
  year,
}) => {
  const [lastAlligned, setLastAlligned] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [employeeInfo, setEmployeeInfo] = useState<IEmployeeDetails>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  let bgColor = "";
  let afterBgColorClass = "";

  if (employeeCardData?.role === "senior nurse") {
    afterBgColorClass = "after:bg-dodoria";
    bgColor = "bg-pink-50";
  } else if (employeeCardData?.role === "nurse") {
    afterBgColorClass = "after:bg-whis";
    bgColor = "bg-blue-50";
  } else if (employeeCardData?.role === "HCA") {
    afterBgColorClass = "after:bg-krillin";
    bgColor = "bg-yellow-50";
  }

  const getEmployeeData = async (employeeData: string) => {
    const res: any = await getEmployeeProfileData(
      employeeData,
      month?.label as string,
      year?.year as string
    );
    if (res?.res) {
      setEmployeeInfo(res?.res);
    }
  };

  useEffect(() => {
    console.log("emp data: ", employeeCardData);
    if (
      employeeCardData?.lastAligned?.date?.includes(
        "No previous duty before this day"
      )
    )
      setLastAlligned("No previous duty");
    else
      setLastAlligned(
        moment(employeeCardData?.lastAligned?.date).format("DD MMM YYYY")
      );
  }, [employeeCardData?.lastAligned?.date, selectedDate]);

  return (
    <>
      <div
        className={`relative rounded-md after:absolute after:rounded-md after:content-[''] after:top-[1px] after:bottom-[1px] after:right-0  ${
          isChecked ? "after:-left-[4px]" : "after:-left-[0px]"
        }  ${isChecked ? afterBgColorClass : "after:bg-[#fff]"}${
          employeeCardData?.leave?.isOnLeave ? "opacity-50" : ""
        }`}
      >
        <div
          className={`  rounded-md p-[10px] mb-[10px] relative z-10 border border-beerus     ${
            isChecked ? bgColor : ""
          }`}
        >
          <div className="flex items-start flex-wrap">
            <span className="inline-flex rounded-full shadow-md">
              <Avatar
                size="md"
                className={`rounded-full border-2 
               ${getBorderColor(employeeCardData?.role)}
              overflow-hidden`}
                imageUrl={""} //add id's route when added
              />
            </span>
            <div className="flex-1 pl-[10px]">
              <div className="flex gap-3">
                <div className="flex-1">
                  <Chip
                    size="sm"
                    className={`text-moon-9 ${getTextColor(
                      employeeCardData?.role
                    )} rounded-sm px-[8px] py-0 h-auto uppercase font-semibold`}
                  >
                    {employeeCardData?.role}
                  </Chip>
                  <h3 className="font-bold 2xl:text-moon-16 text-moon-14  mb-[10px] line-clamp-1">
                    {employeeCardData?.employeeName}
                  </h3>
                </div>
                <span
                  className="text-[20px] "
                  // onClick={() => {
                  //   getEmployeeData(employeeCardData?._id);
                  //   setIsOpen(true);
                  // }}
                >
                  {!isInAutoAllocate && (
                    <GenericAlarmRound
                      className="cursor-pointer"
                      onClick={() => {
                        getEmployeeData(employeeCardData?._id);
                        setIsOpen(true);
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>{" "}
          <div className="flex flex-wrap mb-3">
            <div className="w-1/2">
              <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                Shifts Spent
              </div>
              <div className="text-moon-12">
                <strong className="2xl:text-moon-16 text-moon-14  font-semibold">
                  {employeeCardData?.shiftsSpent}
                </strong>{" "}
                <span className="text-moon-12">
                  {" "}
                  / {employeeCardData?.shiftsAllocated}
                </span>
              </div>
            </div>
            <div className="w-1/2">
              <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                Weekend Shifts Spent
              </div>
              <div>
                <strong className="2xl:text-moon-16 text-moon-14  font-semibold">
                  {employeeCardData?.weekends}
                </strong>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-3">
            <div className="w-1/2 ">
              <div className="w-1/2">
                <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                  Hours Spent
                </div>
                <div>
                  <strong className="2xl:text-moon-16 text-moon-14  font-semibold">
                    {employeeCardData?.hoursSpent}
                  </strong>{" "}
                  <span className="text-moon-12">
                    {" "}
                    / {employeeCardData?.hoursAllocated}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-1/3.5">
              <div className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                Preference
              </div>
              <div
                className={`text-moon-10 uppercase text-center ${
                  employeeCardData?.leave?.isOnLeave
                    ? "text-krillin bg-krillin-10"
                    : employeeCardData?.isOnRoLeave
                    ? "text-frieza bg-frieza-10"
                    : "text-zeno bg-jiren"
                } tracking-[0.5px]`}
              >
                {employeeCardData?.leave?.isOnLeave
                  ? employeeCardData?.leave?.leaveType
                  : employeeCardData?.isOnRoLeave
                  ? "RO Applied"
                  : "No Preferences"}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap mb-2.5">
            <div className="w-1/2 text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
              last aligned
            </div>
            {selectedDate ? (
              <div className={`w-1/2 flex align-middle `}>
                {employeeCardData?.lastAligned?.shift === "day" ? (
                  <Icon className="text-krillin" icon="uil:sun" />
                ) : employeeCardData?.lastAligned?.shift === "night" ? (
                  <OtherMoon />
                ) : (
                  ""
                )}
                <p className="text-moon-10 font-bold uppercase text-black tracking-[0.5px]">
                  {lastAlligned}
                </p>
              </div>
            ) : (
              <span className="text-moon-10 uppercase text-gray-400 tracking-[0.5px]">
                Select a Date
              </span>
            )}
          </div>
          {/* <div className="flex items-center flex-wrap">
              <span className="text-moon-10 uppercase text-gray-400 tracking-[0.5px] mr-[5px]">
                Preference:
              </span>
              <Chip
                className={`text-moon-9 ${
                  employeeCardData?.isOnRoLeave === true //preference === "Applied for leave"
                    ? "text-indigo-500 bg-indigo-100"
                    : selectedShift === "sun"
                    ? "text-krillin  bg-amber-100"
                    : selectedShift === "moon"
                    ? "text-gray-900 bg-gray-100"
                    : "text-pink-500 bg-pink-200"
                } rounded-sm px-[8px] py-0 h-auto uppercase font-semibold`}
              >
                {employeeCardData?.isOnRoLeave
                  ? "APPLIED FOR RO"
                  : selectedShift === "sun"
                  ? "Day"
                  : "Night"}
              </Chip>
            </div> */}
          {/* {preference === "Applied for leave" && (
              <div className="mt-[10px]">
                <Link to="#" className="text-cyan-500 underline text-moon-14">
                  View request
                </Link>
              </div>
            )} */}
        </div>
      </div>
      <DrawerEmployee
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        // employeeData={employeeData}
        employeeInfo={employeeInfo as IEmployeeDetails}
      />
    </>
  );
};

export default EmployeeCard;
