import React, { useEffect, useState } from "react";
import EmployeeShift from "./EmployeeShift";
import FooterCalendar from "../monthly/FooterCalendar/FooterCalendar";
import SimpleBar from "simplebar-react";
import TopbarCalendar from "../TopbarCalendar/TopbarCalendar";
import WeekDays from "../TopbarCalendar/WeekDays";
import WeekDate from "../TopbarCalendar/WeekDate";
import { getEmployeesWeeklySchedule } from "../../../../services/nurse-manager-services/nursemanager.service";
import { useSelector } from "react-redux";
import { data } from "../../schedule/WeeklyScheduleCalendar";
import { RootState } from "../../../../store/store";
import { IWeeklySchedule } from "../../../../types/nurse-manager-interfaces/IWeeklySchedule.interface";
import moment from "moment";
import {
  IMonthDropDown,
  IWeekDropDown,
  IYearDropDown,
} from "../../../../types/IDateDropDown.interface";
import { ScheduleRecordCardData } from "../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
interface Props {
  sectionInfo: any;
  setIsScheduleAvailable: (scheduleCheck: boolean) => void;
  selectedSection?: any;
  selectedRecord?: ScheduleRecordCardData;
  month: IMonthDropDown;
  year: IYearDropDown;
  week: IWeekDropDown;
}
const WeeklyCalendar: React.FC<Props> = ({
  sectionInfo,
  setIsScheduleAvailable,
  selectedSection,
  selectedRecord,
  month,
  year,
  week,
}) => {
  const [employeeSchedules, setEmployeeSchedules] = useState<IWeeklySchedule>({
    employeeSchedules: [],
  });
  // week schedule api call
  const { selectedDuration, date } = useSelector(
    (state: RootState) => state.time
  );
  const { User } = useSelector((state: RootState) => state.auth);
  const section = useSelector((state: RootState) => state.section);
  const fetchEmployeesWeeklySchedule = async () => {
    const res: any = await getEmployeesWeeklySchedule(
      sectionInfo ? sectionInfo?.month : month?.label,
      sectionInfo ? sectionInfo.year : year?.year,
      week?.label as number,
      User?.assignedSection !== "All"
        ? (User?.assignedSection as string)
        : sectionInfo !== undefined
        ? sectionInfo?.section
        : selectedSection?._id,
      sectionInfo?._id ?? selectedRecord?._id
    );
    if (res?.res) {
      res?.res?.response?.length === 0
        ? setIsScheduleAvailable(false)
        : setIsScheduleAvailable(true);
      setEmployeeSchedules(res?.res);
    }
  };

  // const [selectedWeek] = data
  //   ?.filter((week: any) => week?.name === selectedDuration)
  //   .map((week: any) => week?.label);
  // data?.find((week: any) => week?.name === selectedDuration);

  function getWeekDatesAndDayNames(
    weekNumber: number,
    year: number,
    month: number
  ) {
    const startDate = moment()
      .year(year)
      .month(month - 1)
      .date(1);
    // startDate.isoWeekday(1); // Setting to the first day of the week (Monday)
    startDate.isoWeekday(); //setting the first date of the month to the frst day of the week
    // Adjust to the specified week
    startDate.add(weekNumber - 1, "weeks");

    const weekDates = [];
    const dayNames = [];
    const daysInMonth = startDate.daysInMonth();

    for (let i = 0; i < 7; i++) {
      if (startDate.date() <= daysInMonth) {
        weekDates.push(startDate.clone());
        dayNames.push(startDate.format("dddd")); // Full day name (e.g., "Sunday")
      }
      startDate.add(1, "day");
    }

    return { weekDates, dayNames };
  }

  // console.log("selected Week", selectedWeek);
  // const weekNumber = date?.week; // Week number you want to get
  // const year = date?.year; // Year of the month
  // const month = date?.currentMonth; // Month (January is 1, February is 2, ..., December is 12)

  // // console.log(" check: ", weekNumber, "  ", month, "   ", year);

  const { weekDates, dayNames } = getWeekDatesAndDayNames(
    +week?.label,
    year?.year as number,
    month?.label as number
  );

  // weekDates.forEach((date, index) => {
  //   console.log(`Date: ${date.format("DD")}, Day: ${dayNames[index]}`);
  // });
  useEffect(() => {
    if (!week.name || !month.name || !year.name || !User?.assignedSection)
      return;
    // console.log("week numbrt", weekNumber);
    // fetchEmployeesWeeklySchedule();
    if (User?.role === "nurse director" && selectedSection?._id) {
      fetchEmployeesWeeklySchedule();
    } else {
      fetchEmployeesWeeklySchedule();
    }
  }, [week, month, year, User, selectedSection]);

  useEffect(() => {
    if (sectionInfo) {
      fetchEmployeesWeeklySchedule();
    }
  }, [sectionInfo]);
  return (
    <>
      {employeeSchedules?.employeeSchedules ? (
        <>
          <div className="flex flex-wrap">
            <div className="w-1/5">
              <TopbarCalendar
                month={month?.monthName as string}
                year={year?.year as string}
              />
            </div>
            <div className="w-4/5">
              <WeekDate
                weekDates={weekDates}
                scheduleId={selectedRecord?._id as string}
              />
            </div>
          </div>
          <div className="flex flex-wrap bg-beerus">
            <div className="w-1/5">
              <div className="h-[56px] px-[30px] -[30px] py-2  border border-beerus border-s-0 flex items-center">
                <h3 className="text-moon-18 font-medium transition-colors">
                  Employee
                </h3>
              </div>
            </div>
            <div className="w-4/5">
              <WeekDays dayNames={dayNames} />
            </div>
          </div>

          {/* <SimpleBar className="h-[calc(100vh-553px)] overflow-y-auto"> */}
          <SimpleBar className="table_height">
            <EmployeeShift employeeSchedules={employeeSchedules} />
          </SimpleBar>
          <FooterCalendar />
        </>
      ) : (
        <div className="forapprovel flex items-center justify-center flex-col text-center">
          <img
            src={"images/no-schedule-for-month.svg"}
            alt="NO SCHEDULE AVAILABLE"
          />
          <p className="font-bold mt-5">
            {date?.month + " " + date?.year}{" "}
            <span className="font-semibold text-trunks">
              schedule pending for approval
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default WeeklyCalendar;

// import React, { useEffect, useState } from "react";
// import EmployeeShift from "./EmployeeShift";
// import FooterCalendar from "../monthly/FooterCalendar/FooterCalendar";
// import SimpleBar from "simplebar-react";
// import TopbarCalendar from "../TopbarCalendar/TopbarCalendar";
// import WeekDays from "../TopbarCalendar/WeekDays";
// import WeekDate from "../TopbarCalendar/WeekDate";
// import { getEmployeesWeeklySchedule } from "../../../../services/nurse-manager-services/nursemanager.service";
// import { useSelector } from "react-redux";
// import { data } from "../../schedule/WeeklyScheduleCalendar";
// import { RootState } from "../../../../store/store";
// import { IWeeklySchedule } from "../../../../types/nurse-manager-interfaces/IWeeklySchedule.interface";
// import moment from "moment";
// const WeeklyCalendar = () => {
//   const [employeeSchedules, setEmployeeSchedules] = useState<IWeeklySchedule>({
//     employeeSchedules: [],
//   });
//   // week schedule api call
//   const { selectedDuration, date } = useSelector(
//     (state: RootState) => state.time
//   );
//   const fetchEmployeesWeeklySchedule = async () => {
//     const res: any = await getEmployeesWeeklySchedule(month, year, weekNumber);
//     if (res?.res) {
//       setEmployeeSchedules(res?.res);
//     }
//   };

//   const [selectedWeek] = data
//     .filter((week: any) => week?.name === selectedDuration)
//     .map((week: any) => week?.label);
//   // data.find((week: any) => week?.name === selectedDuration);

//   function getWeekDatesAndDayNames(
//     weekNumber: number,
//     year: number,
//     month: number
//   ) {
//     const startDate = moment()
//       .year(year)
//       .month(month - 1)
//       .date(1);
//     // startDate.isoWeekday(1); // Setting to the first day of the week (Monday)
//     startDate.isoWeekday(); //setting the first date of the month to the frst day of the week
//     // Adjust to the specified week
//     startDate.add(weekNumber - 1, "weeks");

//     const weekDates = [];
//     const dayNames = [];
//     const daysInMonth = startDate.daysInMonth();

//     for (let i = 0; i < 7; i++) {
//       if (startDate.date() <= daysInMonth) {
//         weekDates.push(startDate.clone());
//         dayNames.push(startDate.format("dddd")); // Full day name (e.g., "Sunday")
//       }
//       startDate.add(1, "day");
//     }

//     return { weekDates, dayNames };
//   }

//   const weekNumber = selectedWeek; // Week number you want to get
//   const year = date?.year; // Year of the month
//   const month = date?.currentMonth; // Month (January is 1, February is 2, ..., December is 12)

//   // console.log(" check: ", weekNumber, "  ", month, "   ", year);

//   const { weekDates, dayNames } = getWeekDatesAndDayNames(
//     weekNumber,
//     year,
//     month
//   );

//   // weekDates.forEach((date, index) => {
//   //   console.log(`Date: ${date.format("DD")}, Day: ${dayNames[index]}`);
//   // });
//   useEffect(() => {
//     if (!weekNumber || !month || !year) return;
//     fetchEmployeesWeeklySchedule();
//   }, [weekNumber]);
//   return (
//     <>
//       <div className="flex flex-wrap">
//         <div className="w-1/5">
//           <TopbarCalendar />
//         </div>
//         <div className="w-4/5">
//           <WeekDate weekDates={weekDates} />
//         </div>
//       </div>
//       <div className="flex flex-wrap bg-beerus">
//         <div className="w-1/5">
//           <div className="h-[56px] px-[30px] -[30px] py-2  border border-beerus border-s-0 flex items-center">
//             <h3 className="text-moon-18 font-medium transition-colors">
//               Employee
//             </h3>
//           </div>
//         </div>
//         <div className="w-4/5">
//           <WeekDays dayNames={dayNames} />
//         </div>
//       </div>

//       <SimpleBar className="h-[calc(100vh-553px)] overflow-y-auto">
//         <EmployeeShift employeeSchedules={employeeSchedules} />
//       </SimpleBar>
//       <FooterCalendar />
//     </>
//   );
// };

// export default WeeklyCalendar;
