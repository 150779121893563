import React, { useEffect, useState } from "react";
import MonthlyScheduleCalendar from "../../../../Components/nurse-manager/schedule/MonthlyScheduleCalendar";
import ScheduleEmployDay from "../../../../Components/nurse-manager/ScheduleNavDay";
import ScheduleStatus from "../../../../Components/nurse-manager/layouts/header/ScheduleStatus";
import ViewRecord from "../Schedule/ViewRecord/ViewRecord";
import {
  deleteSchedules,
  getSchedulesHistory,
} from "../../../../services/nurse-manager-services/nursemanager.service";
import WeekCalendar from "../Schedule/WeekCalendar/Index";
import { useSelector } from "react-redux";
import { RootState, store } from "../../../../store/store";
import { useLocation, useNavigate } from "react-router";
import {
  setDate,
  setSelectedCalendar,
  setSelectedShift,
} from "../../../../features/nurse-manager/Time Slice/timeSlice";
import moment from "moment";
import WeeklyScheduleCalendar from "../../../../Components/nurse-manager/schedule/WeeklyScheduleCalendar";
import userEvent from "@testing-library/user-event";
import { setSelectedFilter } from "../../../../features/nurse-manager/Week Filter/weekSlice";
import {
  IScheduleHistory,
  ScheduleRecordCardData,
} from "../../../../types/nurse-manager-interfaces/IScheduleHistory.interface";
import {
  setSnackBar,
  setToastMessage,
} from "../../../../features/ToastSlice/toastSlice";

export interface scheduleMonthYear {
  month: number;
  year: number;
}

interface Section {
  _id: string;
  sectionName: string;
}
const AddSchedule = () => {
  const [selectedScheduleId, setSelectedScheduleId] = useState<string>("");
  const [pageNumber, setPageNumber] = useState(1);
  const [scheduleRecordsData, setScheduleRecordsData] =
    useState<IScheduleHistory>();
  const [selectedRecord, setSelectedRecord] =
    useState<ScheduleRecordCardData>();
  const [isScheduleSelected, setIsShowSchedule] = useState<boolean>(false);
  const [selectSection, setSelectedSection] = useState<Section>({
    sectionName: "Choose Sections",
    _id: "",
  });
  const fetchScheduleRecords = async (page: number) => {
    setPageNumber(page);
    const res = await getSchedulesHistory(page);
    if (res?.res) {
      setScheduleRecordsData(res?.res as IScheduleHistory);
    }
  };

  const { User } = useSelector((state: RootState) => state?.auth);

  const location = useLocation();

  useEffect(() => {
    if (isScheduleSelected) return;
    const currentDate = moment();
    fetchScheduleRecords(pageNumber);
    // const currentDate = moment("2023-10-08");
    const dateInfo = moment({
      month: currentDate?.month(),
      year: currentDate?.year(),
      day: currentDate?.date(),
    });

    // console.log("DATE", Math.ceil(currentDate?.date() / 7));
    // dayIndex = dayIndex === 0 ? 6 : dayIndex - 1;
    // const currentWeek = Math.floor((moment().date() + dayIndex - 1) / 7);

    // console.log("MONTH", Math.ceil(currentDate?.date() / 7));
    store.dispatch(setSelectedCalendar("month"));
    store.dispatch(setSelectedShift("all"));
    store.dispatch(
      setDate({
        day: +dateInfo?.format("DD"),
        month: dateInfo?.format("MMMM"),
        year: +dateInfo?.format("YYYY"),
        currentMonth: +dateInfo?.format("MM"),
        week: Math.ceil(currentDate?.date() / 7),
      })
    );

    // location.state.
    if (location?.state?._id) {
      setSelectedScheduleId(location?.state?._id);
      setIsShowSchedule(true);
      setSelectedRecord(location?.state);
    }
  }, [isScheduleSelected]);

  const selectedCalendar = useSelector(
    (state: RootState) => state?.time?.selectedCalendar
  );

  const onChangeSection = (data: Section) => {
    setSelectedSection(data);
  };

  const deleteSchedule = async (scheduleId: string) => {
    try {
      const res: any = await deleteSchedules(scheduleId);
      if (!res?.err) {
        await fetchScheduleRecords(pageNumber);
        store.dispatch(setSnackBar("success"));
        store.dispatch(setToastMessage("Schedule deleted successfully"));
      }
    } catch (err) {
      // store.dispatch(setSnackBar("error"));
      // store.dispatch(setToastMessage('There is some error'));
    }
  };

  return (
    <>
      {/* <ScheduleEmployDay /> */}
      <ScheduleStatus
        section={selectSection}
        onChangeSection={onChangeSection}
        controlView={false}
      />
      {!isScheduleSelected &&
      (User?.role === "nurse manager" ||
        User?.temporaryRole === "nurse manager") ? (
        <ViewRecord
          scheduleRecordsData={scheduleRecordsData as IScheduleHistory}
          setSelectedRecord={setSelectedRecord}
          setIsShowSchedule={setIsShowSchedule}
          setSelectedScheduleId={setSelectedScheduleId}
          deleteSchedules={deleteSchedule}
          getRecords={fetchScheduleRecords}
          pageNumber={pageNumber}
        />
      ) : (User?.role === "nurse director" && selectSection?._id !== "") ||
        User?.role === "nurse manager" ||
        User?.temporaryRole === "nurse manager" ? (
        <>
          {selectedCalendar === "month" ? (
            <MonthlyScheduleCalendar
              selectedSection={selectSection}
              selectedRecord={selectedRecord}
              selectedScheduleId={selectedScheduleId}
              setSelectedScheduleId={setSelectedScheduleId}
              setIsShowSchedule={setIsShowSchedule}
            />
          ) : selectedCalendar === "week" ? (
            <WeeklyScheduleCalendar
              selectedSection={selectSection}
              selectedRecord={selectedRecord}
              setIsShowSchedule={setIsShowSchedule}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="sectionsvg flex items-center justify-center text-center">
          <img
            src={"images/section-select-for-schedule.svg"}
            className="2xl:max-w-auto max-w-[500px]"
            alt="Choose Section First"
          />
        </div>
      )}
    </>
  );
};

export default AddSchedule;
