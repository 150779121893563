import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Job } from "../../../types/operation-manager-interfaces/IJobHistroy.interface";
import "react-tooltip/dist/react-tooltip.css"; // Import the default styles
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import GenericTable from "./Table";
import { getFilePattern } from "../../../services/branch-admin-services/files.service";
import { useDispatch } from "react-redux";
import {
  setSnackBar,
  setToastMessage,
} from "../../../features/ToastSlice/toastSlice";
import moment from "moment";
import { fetchRecentHistory } from "../../../services/operation-manager-services/job.service";
import { setHistory } from "../../../features/history/history.slice";
import {
  ControlsEye,
  GenericDownload,
  GenericUpload,
  SoftwareData,
  TextListView,
} from "@heathmont/moon-icons-tw";
import { Modal } from "@heathmont/moon-core-tw";
import HistoryTable from "./HistoryTable";

type UploadedCsvProps = {
  fileGroups: Job[];
  onDelete: (id: string, name: string) => void;
  onUploadData: (file: File, type: string) => void;
};

const UploadedCsv: React.FC<UploadedCsvProps> = ({
  fileGroups,
  onUploadData,
}) => {
  const columnHelper = createColumnHelper<Job>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedJobType, setSelectedJobType] = useState<string | null>(null);

  useEffect(() => {
    setIsInitialized(true);
    return () => setIsInitialized(false);
  }, []);

  const handleGetHistory = async (type: string) => {
    setSelectedJobType(type);
    setIsModalOpen(true);
    const history = await fetchRecentHistory("", type);
    if (history?.res) {
      dispatch(setHistory(history.res));
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedJobType(null);
  };

  const handleTemplateDownload = async (type: string) => {
    try {
      const res: any = await getFilePattern(type.toLowerCase());
      if (res?.url) {
        window.open(res?.url, "_blank");
      }
    } catch (error) {
      dispatch(setSnackBar("error"));
      dispatch(setToastMessage("Sorry, couldn't download template file."));
    }
  };

  const handleDownloadFile = (url?: string) => {
    if (!url) {
      dispatch(setSnackBar("error"));
      dispatch(setToastMessage("Sorry, no file available."));
      return;
    }
    window.open(url, "_blank");
  };

  const handleViewData = (id?: string) => {
    if (id) {
      navigate(`job/${id}`);
    } else {
      dispatch(setSnackBar("error"));
      dispatch(setToastMessage("Sorry, no file available."));
    }
  };

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>, jobType: string) => {
      console.log("File upload triggered", {
        hasFiles: !!e.target.files,
        jobType,
      });
      if (e.target.files && e.target.files[0]) {
        onUploadData(e.target.files[0], jobType);
        e.target.value = "";
      }
    },
    [onUploadData]
  );

  const columns = [
    columnHelper.accessor("jobType", {
      header: () => <span>Entity</span>,
      cell: (info) => <span className="text-gray-400">{info.getValue()}</span>,
    }),
    columnHelper.accessor("jobId.fileName", {
      header: () => "Job File Name",
      cell: (info) => (
        <span className="truncate max-w-[200px]">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor("jobId.status", {
      header: () => "Status",
      cell: (info) => (
        <span className="truncate text-gray-400">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor("lastRun", {
      header: () => "Last Ran",
      cell: (info) => (
        <span className="truncate text-gray-400">
          {info.row.original.lastRun &&
            moment(new Date(info.row.original.lastRun)).format(
              "DD MMM YYYY hh:mm a"
            )}
        </span>
      ),
    }),
    columnHelper.accessor("_id", {
      header: () => <span className="block text-center">Action</span>,
      cell: (info) => {
        const jobType = info.row.original.jobType;

        return (
          <div className="relative text-center">
            {isInitialized && (
              <input
                type="file"
                key={`file-${info.row.original._id}-${isInitialized}`}
                id={`file-${info.row.original._id}`}
                accept=".csv"
                className=" opacity-0 inset-0 absolute cursor-pointer z-10"
                onChange={(e) => handleFileUpload(e, jobType)}
              />
            )}
            <label
              htmlFor={`file-${info.row.original._id}`}
              className="cursor-pointer bg-blue-50 text-blue-500 inline-flex gap-1 px-4 py-2 rounded-md"
            >
              <GenericUpload fontSize={20} />
              <span className="truncate  "> Upload File</span>
            </label>
          </div>
        );
      },
    }),
    columnHelper.accessor("jobId.url", {
      header: () => <span className="block text-center">View Data</span>,
      cell: (info) => (
        <div className="text-center">
          <span
            onClick={() =>
              handleViewData(
                typeof info.row.original.jobId === "string"
                  ? info.row.original.jobId
                  : info.row.original.jobId?._id
              )
            }
            className="truncate cursor-pointer bg-orange-50 text-orange-500 inline-flex gap-1 px-4 py-2 rounded-md"
          >
            <ControlsEye fontSize={20} />
            View Data
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("jobId.employees.items", {
      header: () => (
        <span className="block text-center">Download Template</span>
      ),
      cell: (info) => (
        <div className="text-center">
          <span
            onClick={() => handleTemplateDownload(info.row.original.jobType)}
            className="truncate cursor-pointer bg-cyan-100 text-cyan-800 inline-flex gap-1 px-4 py-2 rounded-md"
          >
            <GenericDownload fontSize={20} />
            Download Template
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("jobId.patients.currentPage", {
      header: () => <span className="block text-center">Download Data</span>,
      cell: (info) => (
        <div className="text-center">
          <span
            onClick={() =>
              handleDownloadFile(
                typeof info.row.original.jobId !== "string"
                  ? info.row.original.jobId?.url
                  : undefined
              )
            }
            className="truncate cursor-pointer bg-green-100 text-green-800 inline-flex gap-1 px-4 py-2 rounded-md"
          >
            <SoftwareData fontSize={20} />
            Download Data
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("jobId.patients.items.batchId", {
      header: () => "View History",
      cell: (info) => (
        <span
          onClick={() => handleGetHistory(info.row.original.jobType)}
          className="truncate cursor-pointer bg-black/5 text-black inline-flex gap-1 px-4 py-2 rounded-md"
        >
          <TextListView fontSize={20} />
          View History
        </span>
      ),
    }),
  ];

  return (
    <div className="space-y-2 mb-2">
      <GenericTable noPagination data={fileGroups} columns={columns} />
      <Modal open={isModalOpen} onClose={closeModal}>
        <Modal.Backdrop />
        <Modal.Panel className="max-w-[800px]">
          <h2 className="text-xl font-bold  p-4  border-b-1 border-beerus">
            History for {selectedJobType}
          </h2>
          <div className="p-4 pt-0">
            <HistoryTable
              selectedType={selectedJobType}
              getHistory={handleGetHistory}
            />
          </div>
        </Modal.Panel>
      </Modal>
    </div>
  );
};

export default UploadedCsv;
